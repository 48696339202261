<template>
  <v-row align="center" class="mx-2" v-if="status">
    <v-icon class="mr-1" color="warning" v-if="isPaused">
      mdi-pause
    </v-icon>
    <v-icon class="mr-1" color="error" v-if="isProblems">
      mdi-alert-decagram-outline
    </v-icon>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-column mr-4">
        <v-icon class="mr-4" :color="color" dark v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
        </div>
      </template>
      <span>{{ time | smartTimeFormat }}</span>
    </v-tooltip>

    <v-slider
      :value="progress"
      :color="color"
      track-color="light-grey"
      min="0"
      max="100"
      readonly
      persistent-hint
      :hint="message"
    ></v-slider>
  </v-row>
</template>

<script>
import { Labels } from "@/store/modules/task"

export default {
  name: "TaskStatus",

  beforeMount() {},
  data: () => ({}),
  props: {
    status: {
      type: Object,
      default: null
    }
  },
  computed: {
    isPaused() {
      return this.status.paused
    },
    isProblems() {
      return this.status.problems
    },
    color() {
      return Labels.get(this.status.label_id).color
    },
    message() {
      if (this.isProblems) {
        return this.status.problems
      }
      return this.status.message
    },
    title() {
      return Labels.get(this.status.label_id).title
    },
    time() {
      return this.status.created_at
    },
    icon() {
      return Labels.get(this.status.label_id).icon
    },
    progress() {
      return this.status.progress
    }
  },
  methods: {},
  watch: {}
}
</script>
