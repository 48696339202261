import httpClient from './http.client'
const { format } = require('date-fns')

class UserService {
  getMainConditions() {
    return [
      { id:"all", name: "Wszyscy", conditions: ["is_active=true"]},
      { id:"admins", name: "Administratorzy", conditions: ["is_superuser=true", "is_active=true"] },
      { id:"patrols", name: "Patrole", conditions: ["is_patrol=true", "is_active=true"] },
      { id:"patrolman", name: "Interwencyjni", conditions: ["is_active=true", "groups__name=_patrolman"] },
      { id:"dispatchers", name: "Operatorzy", conditions: ["is_dispatcher=true", "is_active=true"] },
      { id:"asystenci", name: "Asystenci", conditions: ["is_active=true", "groups__name=_assistant_dispatcher"] },
      { id:"services", name: "Serwisanci", conditions: ["is_servicer=true", "is_active=true"] },
      { id:"external", name: "Zewnętrzni", conditions: ["is_external=true", "is_active=true"] },
      { id:"blocked", name: "Zablokowani", conditions: ["is_active=false"] }
    ]
  }
  getMainOrdering() {
    return [
      { name: "Ostatnio dodani", ordering: null },
    ];
  }
  get(id) {
    return httpClient.get('v1/users/' + id)
  }
  createOrUpdateUser(user) {
    console.log("create or update user", user)
    if (user.id) {
      return httpClient.put(`v1/users/${user.id}`, user)
    } else {
      return httpClient.post('v1/users', user)
    }
  }

  fetchActiveUsersInGroup(groupName=null, search = "", conditions = [], page = 1) {
    conditions.push("is_active=true")
    return this.privateFetchUsers(groupName, search, conditions, page)
  }

  fetchActiveUsers(search = "", conditions = [], page = 1) {
    conditions.push("is_active=true")
    return this.fetchUsers(search, conditions, page)
  }

  fetchUsers(search = "", conditions = [], page = 1, ordering = null) {
    return this.privateFetchUsers(null, search, conditions, page, ordering)
  }

  privateFetchUsers(groupName =null, search = "", conditions = [], page = 1, ordering = null) {
    let query = ''
    if (search != null && search.length > 0) {
      if (query.length > 0) {
        query += "&"
      }
      query = `search=${search}`
    }
    if (groupName) {
      if (query.length > 0) {
        query += "&"
      }
      query += `groups__name=${groupName}`
    }
    for (const condition of conditions) {
      if (query.length > 0) {
        query += "&"
      }
      query += `${condition}`
    }
    if (query.length > 0) {
      query += "&"
    }
    query += `page=${page}`
    if (ordering) {
      if (query.length > 0) {
        query += "&"
      }
      query += `ordering=${ordering}`
    }
    return httpClient.get(`v1/users?${query}`).then(res => res.data)
  }

  fetchDispatchers(search) {
    return this.fetchActiveUsers(search, ["is_dispatcher=true&ordering=name"])
  }
  fetchDashboard(search = "") {
    return this.fetchActiveUsers(search, [
      'is_patrol=true&is_external=false&details=dashboard&ordering=name',
    ]);
  }
  fetchPatrols(search="") {
    return this.fetchActiveUsers(search, ["is_patrol=true&ordering=name"])
  }
  fetchPatrollers(search) {
    return this.fetchActiveUsersInGroup('_patrolman', search, ["ordering=name"])
  }
  fetchAssistents(search) {
    return this.fetchActiveUsersInGroup('_assistant_dispatcher', search, ["ordering=name"])
  }

  createWorkingTime(payload) {
    return httpClient.post('v1/working_times', payload)
  }
  fetchWorkingTimeNow() {
    return httpClient.get(`v1/working_times?when=now`)
  }
  fetchWorkingTimeToday() {
    return httpClient.get(`v1/working_times?when=today`)
  }
  fetchWorkingTimeInDay(date) {
    return httpClient.get(`v1/working_times?when=${format(date, 'yyyy-MM-dd')}`)
  }
  fetchPatrolSquad(patrol_id, date) {
    return httpClient.get(
      `v1/working_times?when=${date.toISOString()}&parent_id=${patrol_id}`
    );
  }
  fetchWorkingTime(date) {
    return httpClient.get(`v1/working_times?when=${date.toISOString()}`)
  }
  deleteWorkingTime(id) {
    return httpClient.delete(`v1/working_times/${id}`)
  }
  workingTimedownload(date) {
    if (date) {
      return httpClient.get(`v1/working_times/-/download?when=${date.toISOString()}`, {
        responseType: 'blob',
      })
    } else {
      return httpClient.get(`v1/working_times/download`, {
        responseType: 'blob',
      })
    }
  }
  userMemberships(id) {
    return httpClient.get(`v1/users/${id}/memberships`).then(res => res.data)
  }
}

export default new UserService()