// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, { directives: { ripple } })

const theme = {
  primary: '#f64e65',
  secondary: '#f78062',
  accent: '#f64e60',
  info: '#1068bf',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
  details: '#666666',
  critical: '#FF5252',
};

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  // lang: { t: (key, ...params) => {i18n.t(key, params)} },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
