import date from '@/filters/date'
import user from '@/filters/user'
import site from '@/filters/site'
import event from '@/filters/event'
import channels from '@/filters/channels'

export default {
  date,
  user,
  site,
  event,
  channels
}