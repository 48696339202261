import * as Axios from 'axios'
import store from '../store/index'
import router from '@/router'

var baseURL = '/api'
if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://localhost:8000/api'
}
// process.env.VUE_APP_BASE_URL | ''
console.log('BASE URL ', baseURL)

// Make Axios play nice with Django CSRF
Axios.defaults.xsrfCookieName = 'csrftoken'
Axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const httpClient = Axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8;',
  },
})

const authInterceptor = (config) => {
  const token = store.getters['auth/accessToken']
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

httpClient.buildQuery = function buildQuery(q = { search: "", conditions: [], page: 1, ordering: null }) {
  let query = ''
  if (q.search != null && q.search.length > 0) {
    query = `search=${q.search}`
  }
  for (const condition of q.conditions) {
    if (query.length > 0) {
      query += "&"
    }
    query += `${condition}`
  }
  if (query.length > 0) {
    query += "&"
  }
  query += `page=${q.page}`
  if (q.ordering) {
    if (query.length > 0) {
      query += "&"
    }
    query += `ordering=${q.ordering}`
  }
  // console.log('building query :>> ', query)
  return query
}

httpClient.interceptors.request.use(authInterceptor)

httpClient.interceptors.response.use((response) => response,
  async (error) => {
    const { status } = error.response
    if (status === 401) {
      await store.dispatch('auth/logout', null, { root: true })
    }
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log('error :>> ', error);
    if (process.env.NODE_ENV === 'development') {
      return Promise.reject(error);
    }
    if (!error.response) {
      console.log("Please check your internet connection.");
      router.push('/pages/error/?status=Serwis%20jest%20niedostepny')
      return Promise.reject(error)
    } else if (error.response.status > 401) {
      router.push(`/pages/error/?status=${error.response.status}`)
      return Promise.reject(error);
    } else if (error.response.status > 409) {
      router.push(`/pages/error/?status=${error.response.status}`);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
)

export default httpClient
