<template>
  <page-content>
    <v-toolbar flat v-if="tabs.length > 1">
      <app-tabs v-model="selectedTab">
        <v-tab v-for="tab of tabs" :key="tab.id" :to="'#' + tab.id">{{
          tab.name
        }}</v-tab>
      </app-tabs>
    </v-toolbar>
    <v-divider></v-divider>
    <app-toolbar>
      <v-col cols="12" md="6">
        <app-filter-field
          label="Kryptonim"
          v-model="filter"
          @input="onFilter"
        ></app-filter-field>
      </v-col>
      <v-col cols="12" md="3">
        <app-date-range-picker
          v-model="rangeSelected"
          :items="ranges"
          :item-text="(item) => item.name"
        ></app-date-range-picker>
      </v-col>
      <v-col cols="12" md="3">
        <app-sort-select
          v-model="sortBySelected"
          :items="sortBy"
          :item-text="(item) => item.name"
        ></app-sort-select>
      </v-col>
    </app-toolbar>

    <v-divider></v-divider>
    <v-card-subtitle>
      <v-row>
        <v-col cols="4">Status</v-col>
        <v-col cols="8">Zdarzenie<v-chip v-if="count > 0" small class="ml-3">{{ count }}</v-chip></v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider></v-divider>
    <task-row v-for="task of tasks" :key="task.id" :task="task"> </task-row>
    <v-card-actions class="justify-center">
      <v-pagination
        v-if="pages > 1"
        v-model="page"
        :length="pages"
        circle
      ></v-pagination>
    </v-card-actions>
  </page-content>
</template>

<script>
import TaskService from "@/services/task.service"
import { Labels } from "@/store/modules/task"

export default {
  components: {},
  name: "AppTasks",
  data() {
    return {
      timer: null,
      selectedTab: null,
      sortBySelected: null, // TaskService.getTaskOrdering()[0],
      rangeSelected: null, //TaskService.getTaskRanges()[0],
      filter: "",
      page: 0
    }
  },
  props: {
    tabs: {
      type: Array,
      default: () => TaskService.getPatrolConditions()
    },
    sortBy: {
      type: Array,
      default: () => TaskService.getTaskOrdering()
    },
    ranges: {
      type: Array,
      default: () => TaskService.getTaskRanges()
    },
    pages: {
      type: Number,
      default: () => 0
    },
    count: {
      type: Number,
      default: () => 0
    },
    extraConditions: {
      type: Array,
      default: () => []
    },
    tasks: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  methods: {
    onFilter() {
      this.refresh()
    },
    refresh() {
      var query = {
        ...this.tabs.find(t => t.id == this.selectedTab).params,
        ...this.rangeSelected.range(),
        page: `${this.page + 1}`,
        ordering: this.sortBySelected.ordering
      }
      if (this.filter) {
        query.search = this.filter
      }
      this.$emit("refresh-request", {
        page: this.page,
        filter: this.filter,
        query: query,
        range: this.rangeSelected.range(),
        ordering: this.sortBySelected?.ordering
      })
    },
    icon(status) {
      return Labels.get(status.label_id).icon
    },
    color(status) {
      const x = `${Labels.get(status.label_id).color}`.split(" ")
      return `${x[0]}--text text--${x[1]}`
    }
  },
  beforeMount() {
    const query = this.$route.query
    this.filter = query.search

    this.sortBySelected = this.sortBy.find(c => c.ordering == query.ordering)?? this.sortBy[0]
    this.sortBySelected = this.sortBy[0]
    this.rangeSelected = this.ranges[0]
    const watches = ['page', 'sortBySelected', 'rangeSelected', 'filter', 'selectedTab']
    watches.forEach((watch) => {
      this.$watch(watch, () => {
        this.refresh()
      })
    })
    this.selectedTab = this.tabs.find(c => c.id == this.$route.hash.replace(/^#/, ""))?.id ?? this.tabs[0].id
    this.timer = setInterval(this.refresh, 5000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
