// Pathify
import { make } from 'vuex-pathify'
import UserService from '@/services/user.service'

const state = {
  patrols: {},
  lastFetched: null
}

const mutations = make.mutations(state)

const actions = {
  fetchPatrols({ commit }) {
    UserService.fetchDashboard().then(response => {
      commit('patrols', response)
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
