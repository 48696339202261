
export const ChannelType = {
  GENERAL: 0,
  PHONE: 1,
  SMS: 2,
  EMAIL: 3,
  // TCP: 3,
  // VOICE: 4,
  ANDROID: 30,
  IOS: 31,
}

export class Channels {

  iconName(channelType) {

    switch(channelType) {
      case ChannelType.GENERAL:
      return 'mdi-account-check-outline'
      case ChannelType.PHONE:
      return 'mdi-phone'
      case ChannelType.SMS:
      return 'mdi-card-text-outline'
      case ChannelType.ANDROID:
      case ChannelType.IOS:
      return 'mdi-cellphone'
      case ChannelType.EMAIL:
      return 'mdi-email'
    }
  }

  allTypes() {
    return ChannelType
  }


}

export default new Channels()