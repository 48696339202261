<template>
  <div>
    <div class="v-card-subtitle">
      {{ event.code | codeAsString }}
    </div>
    <div class="text-body-2">
      {{ event.details.info }}
    </div>
  </div>
</template>

<script>

export default {
  name: "EventText",

  computed: {},
  props: {
    event: {
      type: Object,
      default: null
    }
  }
}
</script>