<template>
  <v-tooltip bottom v-if="visible" :disabled="!hasTooltip">
    <template v-slot:activator="{ on }">
      <v-btn  v-bind="$attrs" v-on="on" class="mx-1" color="info" @click="onClick">
        <v-icon :small="small">{{ icon }}</v-icon>
        {{ text }}
      </v-btn>
      <slot />
    </template>
    <span v-if="hasTooltip">{{ tooltip }}</span>
  </v-tooltip>
  <!-- <icon-btn color="Info" v-bind="$attrs" v-on="$listeners">
    {{  text }}
  </icon-btn> -->
</template>

<script>
export default {
  name: "CreateBtn",
  computed: {
    hasTooltip() {
      return this.tooltip
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: "Nowy",
    },
    tooltip: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "mdi-car"
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
