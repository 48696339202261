<template>
  <v-timeline-item :color="task.color" :key="task.id" :value="task">
    <!-- <template v-slot:icon> -->
    <!-- <v-avatar> -->
    <!-- <span class="white--text text-h5">{{ task.task.index }} sdfsdf ds dsf</span> -->
    <!-- <img src="https://i.pravatar.cc/64"> -->
    <!-- </v-avatar> -->
    <!-- </template> -->
    <span slot="opposite"> {{ task.createdAt | dateTimeMedium }}</span>
    <v-card>
      <v-card-title>
        <v-chip
          class="mr-1"
          v-for="item of task.assignees"
          :key="item.id"
          outlined
          small
          >{{ item.user | userName }}
        </v-chip>
        <div class="mr-3">{{ task.title }}</div>
        <v-spacer></v-spacer>
        <v-chip
          class="mr-1"
          v-for="item of task.chips"
          :key="item.id"
          :color="item.color"
          :text-color="item.textColor"
          small
        >
          <v-icon small left> {{ item.icon }} </v-icon>
          {{ item.name }}
        </v-chip>
        <div class="mr-8">
          {{ relativeDate(task.createdAt) }}
        </div>
        <v-tooltip bottom v-for="action in task.actions" :key="action.text">
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="action.visible"
              v-on="on"
              icon
              small
              @click="action.click(task, action)"
            >
              <v-icon>{{ action.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ action.text }}</span>
        </v-tooltip>
      </v-card-title>
      <v-divider
        v-if="task.description != null && task.description.length > 0"
      ></v-divider>
      <v-card-text
        v-if="task.description != null && task.description.length > 0"
      >
        {{ task.description }}
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { TaskItem } from './TaskItem'
// import { UserTaskType } from '@/store/modules/task'
import { formatDistance, parseJSON } from 'date-fns'
import { defaultLocale } from '@/filters/date'

export default {
  name: 'TaskTimelineItem',

  props: {
    task: TaskItem,
    // fullHeader: Boolean,
    // icon: String,
    // iconSmall: Boolean,
    // reveal: Boolean,
    // header: String,
    // subtitle: String,
    // title: String,
  },

  computed: {
    hasTitle() {
      return (
        this.title || this.subtitle || this.$slots.title || this.$slots.subtitle
      )
    },
  },
  filters: {
    userName: function(user) {
      if (!user) return ''
      let displayName = ''
      if (user.first_name != null && user.first_name.length > 0) {
        displayName += user.first_name
      }
      if (user.last_name != null && user.last_name.length > 0) {
        if (displayName.length > 0) {
          displayName += ' '
        }
        displayName += user.last_name
      }
      if (displayName.length == 0) {
        displayName = user.username
      }
      return displayName
    },
  },
  methods: {
    relativeDate(value) {
      if (this.task.event == null) {
        return 'unknown'
      }
      const base = this.task.event.created_at
      if (base == null) return ''
      if (!value) return ''
      const date = parseJSON(value)
      return formatDistance(date, parseJSON(base), {
        addSuffix: false,
        locale: defaultLocale(),
      })
    },
  },
}
</script>
