<template>
  <v-btn color="success" text v-bind="$attrs" v-on="$listeners">
    {{  text }}
  </v-btn>
</template>

<script>
export default {
  name: "ApplyBtn",
  props: {
    text: {
      type: String,
      default: "OK",
    },
  },
}
</script>
