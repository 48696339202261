export const CategoryId = {
  ALL: 0,
  ALARM: 1,
  WARNING: 2,
  STATUS: 3,
  SERVICE: 4,
  EDIT: 5,
  OTHER: 6,
};

export class Events {
}

export class Categories {
  static get(id) {
    return this.all().find((item) => item.name == id);
  }

  static all() {
    return [
      { id: CategoryId.ALL, name: 'Wszystkie', color: 'teal' },
      { id: CategoryId.ALARM, name: 'Alarm', color: 'red' },
      { id: CategoryId.WARNING, name: 'Awaria', color: 'orange' },
      { id: CategoryId.STATUS, name: 'Status', color: 'blue' },
      { id: CategoryId.SERVICE, name: 'Serwis', color: 'green' },
      { id: CategoryId.EDIT, name: 'Edycja bazy', color: 'purple' },
      { id: CategoryId.OTHER, name: 'Inne', color: 'grey' },
    ];
  }
}

export default new Events();
