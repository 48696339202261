<template>
  <v-icon
    v-bind="$attrs"
  >
    {{ iconName }}
  </v-icon>
</template>

<script>

import UserTask from "@/store/modules/task"

export default {
  name: 'TaskIcon',

  computed: {
    iconName () {
      return UserTask.iconName(this.task)
    }
  },
  props: {
    task: {
      type: [String, Number],
      default: 'patrol'
    }
  },
}
</script>