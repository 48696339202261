<template>
  <div>
    <dialog-card title="Edytuj komentarz" :actions="actions" :handler="handlerClick" ref="card">
      <v-autocomplete autofocus v-model="editedValue.message" return-object :items="reasons" label="Przyczyna"
        class="body-1 py-2"></v-autocomplete>
      <v-combobox v-model="editedValue.comment" :items="comments" label="Komentarz" class="body-1 py-2"></v-combobox>
      <v-textarea class="mt-2" label="Dodatkowy komentarz ( Shift+Enter : nowa linia )" v-model="editedValue.more"
        rows="1" auto-grow @keydown.enter.exact.prevent="onEnter"
        @keydown.enter.shift.exact.prevent="editedValue.more += '\n'">
      </v-textarea>
      <!-- <v-text-field v-model="editedValue.message" hide-details="true" label="Komentarz" class="body-1 py-2"
        @keyup.enter.stop="onEnter"></v-text-field> -->
      <!-- <v-text-field v-model="editedValue.comment" hide-details="true" label="Szczegóły" class="body-1 py-2"
        @keyup.enter.stop="onEnter"></v-text-field> -->
    </dialog-card>
  </div>
</template>

<script>

import { LabelId, ReasonsDict, CommentsDict } from "@/store/modules/task"

export default {
  name: "PatrolSummaryCard",
  props: {
    model: {
      default: () => (
        {
          message: "",
          comment: "",
          more: "",
        }),
    }
  },
  data: () => ({
    editedValue: {},
    reasons: ReasonsDict,
    comments: CommentsDict,
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
  }),
  beforeMount() {
    this.editedValue = {
      message: this.model.message,
      comment: this.model.comment,
      more: this.model.more,
    }
  },
  mounted() {
  },
  methods: {
    submit(value) {
      this.$emit("submit", value)
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      if (action.key) {
        this.model.label_id = this.editedValue.message?.type ?? LabelId.DONE
        this.model.progress = 100
        this.model.message = this.editedValue.message?.text ?? null
        this.model.comment = this.editedValue.comment ?? ""
        console.log('this.model 1:>> ', this.model);
        if (this.editedValue.more.length > 0) {
          if (this.model.comment.length > 0) {
            this.model.comment += "\n"
          }
          this.model.comment += this.editedValue.more
        }
        if (this.model.comment.length == 0) {
          this.model.comment = null
        }
      }
      this.$emit('submit', action.key ? this.model : action.key)
    }
  }
}
</script>
