<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="expert">
         <VueCtkDateTimePicker
            v-bind="$attrs"
            input-size="lg"
            v-model="created_at"
            color="#f64e65"
            hint="Niestandardowy czas zdarzenia"
            label="Wybierz datę i godzinę"
            format="YYYY-MM-DD HH:mm"
            formatted="dddd, DD MMMM YYYY HH:mm"
          >
        </VueCtkDateTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <code-combo :code="code" @code-selected="codeSelected" />
      </v-col>
      <v-col cols="12">
        <v-text-field
          dense
          v-model="info"
          :label="$t('event.info')"
          outlined
          hide-details="false"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { format } from "date-fns"

export default {
  name: "EventEditor",

  beforeMount() {
    this.reset()
  },
  props: {
    autocommit: {
      type: Boolean,
      default: false
    },
    expert: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => {
        return {
          created_at: null,
          code: null,
          site: null,
          details: { info: "" }
        }
      }
    }
  },
  data: () => ({
    created_at: null,
    info: null,
    code: null
  }),
  watch: {
    event: function() {
      console.log("event update :>> ", this.event)
      this.reset()
    },
    created_at: function() {
      console.log('watch: this.created_at :>> ', this.created_at);
      if (this.autocommit) {
        this.event.created_at = new Date(this.created_at).toISOString()
      }
    },
    info: function() {
      if (this.autocommit) {
        this.event.details.info = this.info
      }
    }
  },
  filters: {},
  computed: {
    maxDate() {
      const max = this.$options.filters.localDateTime(new Date())
      console.log('max :>> ', max);
      return max
    },
  },
  methods: {
    codeAsString: item =>
      item.code + " " + item.text + " [" + item.category + "]",
    update() {
      console.log("update event from form", this.$refs)
      if (this.created_at) {
        this.event.created_at = this.created_at
      } else {
        this.event.created_at = null
      }
      this.event.code = this.code
      this.event.details.info = this.info
      console.log("event", this.event)
    },
    codeSelected(code) {
      this.code = code
      if (this.autocommit) {
        this.event.code = code
      }
    },
    reset() {
      this.info = this.event.details.info
      this.code = this.event.code
      if (this.event.created_at) {
        this.created_at = this.$options.filters.localDateTime(this.event.created_at)
      } else {
        this.created_at = null
      }
    }
  }
}
</script>
