import { differenceInSeconds, parseJSON } from 'date-fns';
import closeTaskDialog from '@/components/CloseTaskDialog.vue';

export const UserTaskType = {
  DISPATCHER: 1,
  PATROL: 2,
  SERVICE: 3,
};

export const CommentsDict = [
  'Osoby opuściły obiekt',
  'Braki zasilania w rejonie',
  'Próba systemu alarmowego',
  'Na chwilę',
  'Oddzwoni',
  'Opuścił lokal przed przyjazdem patrolu',
  'Tylko powiadamianie',
  'Nie zna hasła',
  'Ma tak zostać',
  'Powiadomiono serwis Security',
  'Przystawka GSM własnością klienta',
  'Alarm przy uzbrajaniu',
  'Opuściiły lokal przed przyjazdem patrolu',
  'Sprawca oddalił się przed przybyciem patrolu',
  'Weryfikacja wizyjna',
  'Zmiana adresu',
  'Sprawdzi później',
  'Sprawdził',
  'Moduł GSM w centrali',
  'Normalna praca',
  'Kot w domu',
  'Odwołano przed dojazdem patrolu',
  'Wyładowany akumulator',
  'Oddalil się przed przybyciem patrolu',
  'Przyjedzie na miejsce',
  'Prosi o serwis Security',
  'Patrol zapoznał się z obiektem',
  'System p. pożarowy',
  'Pracownik w obiekcie',
  'Silny wiatr',
  'Prace remontowe',
  'Brak uwag',
  'Brak zasilania sieciowego',
  'Pouczony opuścił lokal',
  'Kontrola obowiązkowa obiektu przez patrol',
  'Powiadomiono o możliwości braku monitorowania',
  'Przyjedzie na miejsce i sprawdzi',
  'Wchodzi na 15 minut',
  'Rozbrojenie po alarmie',
  'Zatrzymany sprawca kradzieży',
  'Powiadomiono KP2',
  'Opuścił obiekt po przyjeździe patrolu',
  'Niski stan zasilania awaryjnego',
  'Nie wysyłać patrolu',
  'Tak ma pozostać do jutra',
  'Potwierdzono z dokumentu tożsamości',
  'Pracownicy na budowie',
  'Nie reagować do odwołania telefonicznego',
  'Otwarte drzwi balkonowe',
  'Prosi o interwencję patrolu',
  'Odwołanie po przyjeździe patrolu',
  'P190; brak klucza',
  'Słaba widoczność obiektu z kamer',
  'Nie reagować na alarmy',
  'Prosi o podjazd',
  'Kontrola zza ogrodzenia',
  'Nie użyto pilota',
  'Do uzbrojenia',
  'Problem z zasiegiem',
  'Sprawdzono obiekt wewnątrz',
  'Wcześniejsze wejście',
  'Nie nasz system',
  'Powiadomiono Policję',
  'Prośba o serwis',
  'Uchylone okno',
  'Wejście pracownika SNG',
  'Koty na terenie',
  'Powiadomiony o braku możliwości monitorowania',
  'Uchylone okna',
  'Nadal',
  'Wysłać patrol',
  'Nie będzie uzbrojenia',
  'Próba wejścia do hostelu',
  'Karta własnością klienta',
  'System p-poż',
  'Kilkanaście minut',
  'Powrót linii telefonicznej',
  'Sprzątaczka',
  'Do zgłoszenia telefonicznego',
  'Tylko pilot napadowy',
  'Brak komunikatu po linii telefonicznej',
  'Pracownicy w obiekcie',
  'Z powodu zadłużenia',
  'Kontrola obiektu przez patrol',
  'Odwołane przed dojazdem patrolu',
  'Brak uzbrojenia po telefonie',
  'Tylko radio',
  'Sprawdzi',
  'Wielokrotne rozbrojenia w ciągu dnia',
  'Potwierdza',
  'Prace elektryczne',
  'Osoby na terenie obiektu',
  'Asysta patrolu',
  'Powrót',
  'System uzbrojony',
  'Szkolenie pracownika',
  'Próba systemu',
  'Powrót zasilania',
  'Potwierdzono odwołanie w PSP',
  'Opuściły obiekt przed przyjazdem patrolu',
  'Tak może pozostać',
  'Patrol dojechał przed odwołaniem',
  'Oddalili się przed przybyciem patrolu',
  'Sprawdzono zza ogrodzenia',
  'Do oddzwonienia',
  'Kontrola rutynowa obiektu przez patrol',
  'Służba bez uwag',
  'Demontaż systemu',
  'Weryfikacja OWSN',
  'Powrót obrazu z kamer',
  'Powiadomiono KP Wrzeszcz',
  'Na kilkanaście minut',
  'Uchylone okno nad wejściem',
  'Oddalił się przed przybyciem patrolu',
  'Powiadomiona o braku możliwości monitorowania',
  'Brak obrazu z kamer',
  'Powiadomiono KP Stogi',
  'Powiadomiono PSP',
  'Próbny alarm',
  'GSM i SIM klienta',
  'Odwoł',
  'Pęknięta szyba w oknie',
  'Potwierdzono zgłoszenie w PSP',
  'Powiadomiono o braku możliwości monitorowania',
  'Prosi o kontakt serwis Security',
  'Brak możliwości monitorowania',
  'Nie będzie uzbrojenia do rana',
  'Wcześniejsza praca',
  'Odwołano po przyjeździe patrolu',
  'Powiadomiono o braku zasilania sieciowego',
  'Telefoniczne zgłoszenie',
  'Opuściły lokal przed przyjazdem patrolu',
  'Uchylone okno balkonowe',
  'Mają oddzwonić',
  'Brak transmisji testowej GSM',
  'Sam sprawdzi',
  'Uzbroił zdalnie',
  'Otwarte drzwi wejściowe',
  'Agresywny i pijany klient',
  'Sprzątaczka na miejscu',
  'Możliwe rozbrojenia w ciągu dnia',
  'Kilka minut dłużej',
  'Odwołanie przed przyjazdem patrolu',
  'Moduł GSM własnością klienta',
  'Demontaż nadajnika gsm',
  'Prace serwisowe',
  'Problem z systemem',
  'Nie reagować na pierwszy alarm',
  'Wyjaśni',
  'Patrol na miejscu',
  'Zgłoszono serwisowi Security',
  'Karta i GSM własnością klienta',
  'Nie dozwolone rozbrojenie',
  'Bariera zewnętrzna',
  'Własność klienta',
  'Nie reagować do odwołania',
  'Prośba o podjazd',
  'Wsparcie ochrony fizycznej SECURITY',
  'Brak klucza',
  'Po spłacie zadłużenia',
  'Sprawdzi i oddzwoni',
  'Powrót zasilania sieciowego',
  'Otwarta brama wjazdowa',
  'Prace serwisowe Security',
  'Odwołała',
  'Ujęty sprawca kradzieży',
  'Oddzwoniła',
  'Praca całodobowa',
  'Tylko telefon',
  'Niezamknięta brama',
  'Brak uwag po zapoznaniu się z obiektem',
  'Pracownik SNG',
  'Osoba na terenie obiektu',
  'Brak możliwości spawdzenia',
  'Oddaliła się przed przybyciem patrolu',
  'Sprawca oddalił się z miejsca zdarzenia',
  'Do odwołania telefonicznego',
  'Komunikat doszedł do patrolu',
  'Otwarte drzwi',
  'Na kilka minut',
  'Normalny ruch',
  'Kontrola czasu dojazdu',
  'Tak ma pozostać',
  'Wejście pracownika',
  'Wezwano Policję',
  'Brak uwag po kontroli obiektu',
  'Błąd obsługi',
  'Alarm fałszywy',
  'Problem z uzbrojeniem',
  'Potwierdza w PSP',
  'Prace remontowo-budowlane',
  'Długi brak łączności telefonicznej',
  'Brak widocznych śladów włamania',
];

export class UserTask {
  iconName(taskType) {
    switch (taskType) {
      case 'dispatcher':
      case UserTaskType.DISPATCHER:
        return 'mdi-account-tie';
      case 'patrol':
      case UserTaskType.PATROL:
        return 'mdi-car-emergency';
      case 'service':
      case UserTaskType.SERVICE:
        return 'mdi-cog-outline';
    }
  }

  isReadOnly(task, user) {
    if (task == null) {
      return true;
    }
    if (!isClosed(task)) {
      return false;
    }
    if (task.closed_by != user.id) {
      return true;
    }
    // 2 hour can edit
    const now = new Date();
    const closed = parseJSON(task.closed_at);
    const diff = differenceInSeconds(now, closed);
    if (diff > 2 * 60 * 60) {
      return true;
    }
    return false;
  }

  allTypes() {
    return UserTaskType;
  }

  isFinished(task) {
    if (isClosed(task)) return true;
    if (!task.details) return false;
    if (!task.details.history) return false;
    for (const index in task.details.history) {
      if (task.details.history[index].stage == 'Zakończone') {
        return true;
      }
    }
    return false;
  }

  duration(task) {
    if (!task?.created_at) {
      return null;
    }
    const start = parseJSON(task.created_at);
    let end = new Date();
    if (task.closed_at) {
      end = parseJSON(task.closed_at);
    }
    return differenceInSeconds(end, start);
  }
}

export default new UserTask();

export function isPatrol(task) {
  return task?.task_type == UserTaskType.PATROL ?? false;
}
export function isWaitingForPermission(task) {
  return (
    (task?.details?.status?.progress == 99 ?? false) &&
    (!task?.details?.options?.can_closed ?? false)
  );
}

export function isClosed(task) {
  return task?.closed_at != null ?? false;
}

export function rootTask(tasks) {
  return tasks.find((task) => {
    return task.parent == null;
  });
}

export function updateTask(tasks, task) {
  const index = tasks.findIndex((item) => item.id == task.id);
  if (index >= 0) {
    tasks[index] = task;
  }
}

export async function closeTask({ context, event, task, taskService }) {
  const result = await context.$dialog.show(closeTaskDialog, {
    event: event,
    waitForResult: true,
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
  });
  if (result === false || result === undefined) {
    return;
  }
  console.log('result :>> ', result, task);
  var promises = result.payments
    .filter(
      (payment) => (payment.task?.details?.cost?.token ?? 0) != payment.token
    )
    .map((payment) => {
      console.log(
        'payment promis (set cost):>> ',
        payment.task.details?.cost?.token,
        payment.token
      );
      taskService.setCost(event.site.id, payment.task.id, payment.token);
    });
  Promise.all(promises).then(() => {
    return taskService.closeTask(event.site.id, task.id, result.comment, null);
  });
}

export async function setPaymentDialog(
  context,
  paidDialog,
  taskService,
  task,
  site
) {
  let token = 0;
  if (task.details.cost) {
    token = task.details.cost.token;
  }

  const dialogInstance = await context.$dialog.show(paidDialog, {
    token: token,
    site: site,
  });
  dialogInstance.waitForResult = true;
  return dialogInstance.wait().then((result) => {
    if (!result) return;
    return taskService.setCost(site.id, task.id, result).then((response) => {
      return response.data;
    });
  });
}

export async function notifyUsersDialog({
  context,
  notifyDialog,
  siteService,
  taskService,
  event,
  task,
}) {
  const dialogInstance = await context.$dialog.show(notifyDialog, {
    subtitle: context.$options.filters.siteFullName(event.site),
    users: new Promise((resolve) => {
      siteService
        .fetchMembers(event.site.id)
        .then((result) => resolve(result.map((member) => member.user)));
      // .then(result => resolve(result.filter(user => notifiedUsers.find(u => u.id == user.id) == null)))
    }),
    usersSelected: [],
  });
  dialogInstance.waitForResult = true;
  await dialogInstance.wait().then((user) => {
    if (!user) return; //user can be 'false' when dialog is canceled
    if (!task.details.notified) {
      task.details.notified = [];
    }
    if (task.details.notified.find((u) => u.id == user.id)) {
      return;
    }
    // var notified = task.details.notified
    // notified.push({id: user.id, name: this.$options.filters.userName(user)})
    return taskService.putNotifiedUser(event.site.id, task.id, user);
  });
}

export async function foundUsersDialog({
  context,
  foundDialog,
  siteService,
  taskService,
  site,
  task,
}) {
  const dialogInstance = await context.$dialog.show(foundDialog, {
    title: 'Osoba w obiekcie',
    subtitle: context.$options.filters.siteFullName(site),
    users: new Promise((resolve) => {
      siteService
        .fetchMembers(site.id)
        .then((result) => resolve(result.map((member) => member.user)));
      // .then(result => resolve(result.filter(user => notifiedUsers.find(u => u.id == user.id) == null)))
    }),
    usersSelected: [],
  });
  dialogInstance.waitForResult = true;
  return dialogInstance.wait().then((user) => {
    if (!user) return; //user can be 'false' when dialog is canceled
    if (!task.details.found) {
      task.details.found = [];
    }
    if (task.details.found.find((u) => u.id == user.id)) {
      return;
    }
    // var notified = task.details.notified
    // notified.push({id: user.id, name: this.$options.filters.userName(user)})
    return taskService.putFoundUser(site.id, task.id, user);
  });
}

export async function cancelTask({
  context,
  cancelTaskDialog,
  siteService,
  taskService,
  event,
  task,
}) {
  const dialogInstance = await context.$dialog.show(cancelTaskDialog, {
    users: new Promise((resolve) => {
      siteService
        .fetchMembers(event.site.id)
        .then((result) => resolve(result.map((member) => member.user)));
    }),
  });
  dialogInstance.waitForResult = true;
  await dialogInstance.wait().then((result) => {
    if (!result) {
      return;
    }
    return taskService.cancelTask(
      event.site.id,
      task.id,
      result.comment,
      null,
      result.user
    );
  });
}

export async function patrolSummaryDialog(
  context,
  summaryTaskDialog,
  taskService,
  task,
  site
) {
  const dialogInstance = await context.$dialog.show(summaryTaskDialog, {});
  dialogInstance.waitForResult = true;
  return dialogInstance.wait().then((result) => {
    if (!result) {
      return;
    }
    return taskService.setStatus(site.id, task.id, result);
  });
}

export function taskTree(tasks) {
  if (!tasks) {
    return null;
  }
  var root = tasks.find((task) => {
    return task.parent == null;
  });
  if (root == null) {
    return null;
  }
  root.subtasks = [];
  tasks.forEach((task) => {
    if (task == root) {
      return;
    }
    if (task.parent == root.id) {
      root.subtasks.push(task);
    }
  });
  return root;
}

export function isOpenTask(tasks) {
  if ((tasks?.length ?? 0) == 0) {
    return false;
  } else if (rootTask(tasks)?.closed_at == null) {
    return true;
  } else {
    return false;
  }
}

/**
 * @param {*} tasks lists of tasks
 * @returns last modified task
 */
export function lastModifiedTask(tasks) {
  var task = tasks[tasks.length - 1];
  for (const t of tasks) {
    if (t.modified_at > task.modified_at) {
      task = t;
    }
  }
  return task;
}

/**
 * @param {*} tasks lists of tasks
 * @returns last modified task
 */
export function isOnTheTask(task) {
  let status = task.details?.status;
  if (!status) {
    return false;
  }
  return status.label_id == LabelId.ON_THE_WAY && !status.paused;
}

export function firstAssigneeUser(task) {
  if (!task?.assignees?.length) {
    return null;
  }
  return task.assignees[0].user;
}

export const LabelId = {
  NEW: 0,
  DELIVERED: 10,
  READ: 20,
  ON_THE_WAY: 30,
  AT_LOCATION: 50,
  CANCELED: 70,
  PAUSED: 80,
  RESUMED: 81,
  POSITIVE: 100,
  NEGATIVE: 101,
  DONE: 102,
  PROBLEMS: 110,
  END_OF_PROBLEMS: 111,
  BUSY: 130,
  IDLE: 131,
  NOTIFICATION: 200,
  COMMENT: 300,
  CLOSE: 1000,
};

export class Labels {
  static get(id) {
    return this.all().find((item) => item.id == id);
  }

  static isFinal(id) {
    return (
      id == LabelId.DONE ||
      id == LabelId.CANCELED ||
      id == LabelId.POSITIVE ||
      id == LabelId.NEGATIVE
    );
  }

  static all() {
    //wprowadzic typ - dla user, task itd ?
    return [
      {
        id: LabelId.NEW,
        title: 'Rozpoczęcie obsługi',
        color: 'teal darken-4',
        icon: 'mdi-send-outline',
        description: null,
        alertTime: 30,
      },
      {
        id: LabelId.DELIVERED,
        title: 'Dostarczony',
        color: 'teal darken-4',
        icon: 'mdi-send-check-outline',
        description: null,
      },
      {
        id: LabelId.READ,
        title: 'Odczytany',
        color: 'teal darken-4',
        icon: 'mdi-send-check-outline',
        description: null,
        alertTime: 30,
      },
      {
        id: LabelId.ON_THE_WAY,
        title: 'W drodze',
        color: 'blue darken-4',
        icon: 'mdi-car',
        description: null,
      },
      {
        id: LabelId.AT_LOCATION,
        title: 'Na miejscu',
        color: 'blue darken-4',
        icon: 'mdi-map-marker',
        description: null,
      },
      {
        id: LabelId.CANCELED,
        title: 'Odwołany',
        color: 'yellow darken-3',
        icon: 'mdi-cancel',
        description: null,
      },
      {
        id: LabelId.PAUSED,
        title: 'Wstrzymany',
        color: 'yellow darken-3',
        icon: 'mdi-pause',
        description: null,
      },
      {
        id: LabelId.RESUMED,
        title: 'Wznowiony',
        color: 'green darken-3',
        icon: 'mdi-pause',
        description: null,
      },
      {
        id: LabelId.POSITIVE,
        title: 'Potwierdzony',
        color: 'light-green darken-2',
        icon: 'mdi-check-circle-outline',
        description: null,
      },
      {
        id: LabelId.NEGATIVE,
        title: 'Fałszywy',
        color: 'blue darken-3',
        icon: 'mdi-check-circle-outline',
        description: null,
      },
      {
        id: LabelId.DONE,
        title: 'Zakończony',
        color: 'grey darken-1',
        icon: 'mdi-check-circle-outline',
        description: null,
      },
      {
        id: LabelId.PROBLEMS,
        title: 'Problemy',
        color: 'red darken-1',
        icon: 'mdi-alert-outline',
        description: null,
      },
      {
        id: LabelId.END_OF_PROBLEMS,
        title: 'Koniec problemów',
        color: 'green darken-1',
        icon: 'mdi-alert-outline',
        description: null,
      },
      {
        id: LabelId.BUSY,
        title: 'Zajęty',
        color: 'teal darken-4',
        icon: 'mdi-sleep',
        description: null,
      },
      {
        id: LabelId.IDLE,
        title: 'Wolny',
        color: 'teal lighten-4',
        icon: 'mdi-sleep',
        description: null,
      },
      {
        id: LabelId.NOTIFICATION,
        title: 'Powiadomienie',
        color: 'teal lighten-4',
        icon: 'mdi-bell-badge-outline',
        description: null,
      },
      {
        id: LabelId.PERSON,
        title: 'Osoba w obiekcie',
        color: 'teal lighten-4',
        icon: 'mdi-eye-outline',
        description: null,
      },
      {
        id: LabelId.COMMENT,
        title: 'Komentarz',
        color: 'teal lighten-4',
        icon: 'mdi-comment-text-outline',
        description: null,
      },
      {
        id: LabelId.CLOSE,
        title: 'Odjazd z obiektu',
        color: 'teal lighten-4',
        icon: 'mdi-check-circle-outline',
        description: null,
      },
    ];
  }
}

export const ReasonsDict = [
  { type: LabelId.POSITIVE, text: 'Włamanie' },
  { type: LabelId.NEGATIVE, text: 'Alarm fałszywy' },
  { type: LabelId.NEGATIVE, text: 'Awaria systemu' },
  { type: LabelId.NEGATIVE, text: 'Błąd obsługi' },
  { type: LabelId.NEGATIVE, text: 'Błąd systemu' },
  { type: LabelId.NEGATIVE, text: 'Błąd użytkownika' },
  { type: LabelId.NEGATIVE, text: 'Inne' },
  { type: LabelId.NEGATIVE, text: 'Napad' },
  { type: LabelId.NEGATIVE, text: 'Osoby na terenie obiektu' },
  { type: LabelId.NEGATIVE, text: 'Otwarte okno' },
  { type: LabelId.NEGATIVE, text: 'Otwarte okno' },
  { type: LabelId.NEGATIVE, text: 'Prace serwisowe' },
  { type: LabelId.NEGATIVE, text: 'Przyczyna nieznana' },
  { type: LabelId.NEGATIVE, text: 'Próbny alarm' },
  { type: LabelId.NEGATIVE, text: 'Zwierze w obiekcie' },
  { type: LabelId.NEGATIVE, text: 'Zwierzęta w obiekcie' },
  { type: LabelId.DONE, text: 'Brak uwag' },
  { type: LabelId.POSITIVE, text: 'Agresywny klient' },
  { type: LabelId.POSITIVE, text: 'Kradzież' },
  { type: LabelId.POSITIVE, text: 'Objekt zabezpieczony' },
  { type: LabelId.POSITIVE, text: 'Próba kradzieży' },
  { type: LabelId.POSITIVE, text: 'Próba włamania' },
];
