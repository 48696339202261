<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="ml-1 mr-3">
          <!-- <v-icon small class="mr-1" color="details">mdi-send-outline</v-icon> -->
          {{ event.created_at | smartTimeFormat }}
        </div>
        <!-- <div v-bind="attrs" v-on="on" class="details--text ml-1 mr-3">
          <v-icon small class="mr-1" color="details">mdi-update</v-icon>
          {{ date | formatDistanceWithPrefix }}
        </div> -->
      </template>
      <div>Data rozpoczęcia</div>
      <div>{{ event.created_at | dateMediumTimeShort }}</div>
      <div>{{ date | formatDistanceWithPrefix }}</div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "EventTime",

  filters: {},
  computed: {
    date: function() {
      return this.event.created_at
    }
  },
  data: () => ({}),
  props: {
    event: null,
    classEx: String
  },
  methods: {}
}
</script>