<template>
  <v-card outlined>
    <v-card-title>Dodaj dostęp</v-card-title>
    <v-col v-if="!forUser">
      <users-select-2 :usersSelected="user" @user-selected="usersChanged" :multiple="false"
        :users="fetchUsers"></users-select-2>
    </v-col>
    <v-col v-if="forUser">
      <sites-combo :site="site" autofocus @site-selected="siteSelected" />
    </v-col>
    <v-col>
      <v-select v-model="selectedAccessLevel" :items="accessLevel" outlined>
      </v-select>
    </v-col>
    <app-card-actions>
      <!-- <dialog-btn v-if="!forUser" @click="onNew">Nowy użytkownik</dialog-btn> -->
      <cancel-btn @click="cancelClick" />
      <apply-btn @click="onInvite" />
    </app-card-actions>
  </v-card>
</template>

<script>
import UserService from "@/services/user.service";
import { AccessLevel } from "@/store/modules/siteMember";
import SiteMember from "@/store/modules/siteMember";

export default {
  name: "InviteSiteMemberCard",
  mounted() {
    this.search = "";
  },
  data: () => ({
    user: null,
    site: null,
    users: new Promise((resolve) => resolve([])),
    search: null,
    accessLevel: SiteMember.accessLevels(),
    selectedAccessLevel: AccessLevel.OWNER,
  }),
  props: {
    forUser: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedAccessLevel(value) {
      console.log(value);
    },
    search(value) {
      console.log("search value")
      this.users = new Promise((resolve) => {
        let conditions = [];
        console.log("confitions", conditions)
        if (value) {
          conditions.push("ordering=date_joined");
        }
        UserService.fetchActiveUsers(value, conditions)
          .then((page) => page.results)
          .then((users) => resolve(users));
      });
    },
  },
  methods: {
    fetchUsers(value) {
      return new Promise((resolve) => {
        let conditions = [];
        if (value) {
          conditions.push("ordering=date_joined");
        }
        UserService.fetchActiveUsers(value, conditions)
          .then((page) => page.results)
          .then((users) => resolve(users));
      });
    },
    // excludeMembers(users) {
    //   return users.filter(user => !this.members.find(member => (user.id === member.id)))
    // },
    usersChanged(value) {
      this.user = value;
    },
    cancelClick() {
      this.$emit("submit", null)
    },
    onInvite() {
      if (this.forUser) {
        this.$emit("submit", { site: this.site, accessLevel: this.selectedAccessLevel });
        // this.site = null
      } else {
        this.$emit("submit", { user: this.user, accessLevel: this.selectedAccessLevel });
        this.user = null;
      }
    },
    onNew() {
      this.$emit("new-user");
    },
    siteSelected(site) {
      this.site = site;
    },
  },
};
</script>
