
const state = {
  packageVersion: process.env.PACKAGE_VERSION || 0,
  buildDate: process.env.PACKAGE_BUILD_DATE
}

const getters = {
  appVersion: (state) => {
    return state.packageVersion
  },
  buildDate: (state) => state.buildDate

}

export default {
  state,
  getters
}
