<template>
  <v-row no-gutters class="align-center" v-if="user">
    <v-col cols="3" align="center">
      <v-avatar class="mp-avatar" size="48px" justify="center">
        <v-img v-if="user.avatar_url" :src="user.avatar_url"></v-img>
      </v-avatar>
    </v-col>
    <v-col cols="9" class="pl-2" v-if="!dense">
      <router-link
        v-if="routerLink"
        class="text-decoration-none font-weight-medium"
        :to="{ name: 'User', params: { user: user, userId: user.id } }"
      >
        {{ user | userName }}
        <chip-blocked v-if="!user.is_active" class="ml-2"></chip-blocked>
        <chip-external v-if="user.is_external" class="ml-2"></chip-external>
      </router-link>
      <div v-if="!routerLink" class="text-decoration-none font-weight-medium">
        {{ user | userName }}
        <chip-blocked v-if="!user.is_active" class="ml-2"></chip-blocked>
      </div>
      <div class="text-subtitle-1">
        <v-icon small class="mr-1" v-if="user.organization || user.job_title">
          mdi-briefcase-outline
        </v-icon>
        {{ workInfo(user) }}
      </div>
      <div>
        <v-icon small class="mr-1" v-if="user.email">mdi-email-outline</v-icon>
        <a
          v-bind:href="`mailto:${user.email}`"
          class="text-decoration-none text-subtitle-1"
        >
          {{ user.email }}
        </a>
      </div>
      <div>
        <v-icon small class="mr-1" v-if="user.phone">mdi-cellphone</v-icon>
        <a
          v-bind:href="`tel:${user.phone}`"
          class="text-decoration-none text-subtitle-1"
        >
          {{ user.phone }}
        </a>
      </div>
      <div v-if="routerLink">
        <v-tooltip bottom v-for="(site, index) in user.sites" :key="index">
          <template v-slot:activator="{ on }">
            <router-link
            class="text-decoration-none font-weight-medium"
            :to="{ name: 'Site', params: { site: site, siteId: site.id } }"
            >
            <v-chip v-if="!site.code_name" v-on="on" small class="mr-2 blue" text-color="white">{{ "Dostęp globalny"}}</v-chip>
            <v-chip v-if="site.code_name" v-on="on" small class="mr-2">{{ site.code_name }}</v-chip>
          </router-link>
          </template>
          <div>{{ site.name }}</div>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "UserInfo",
  data() {
    return {}
  },
  props: {
    routerLink: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    }
  },
  methods: {
    workInfo(user) {
      if (user.job_title && user.organization) {
        return this.$t("user.work.information", {
          job_title: user.job_title,
          organization: user.organization
        })
      } else if (user.job_title) {
        return user.job_title
      } else if (user.organization) {
        return user.organization
      }
    }
  }
}
</script>

<style lang="sass">
.mp-avatar
  border-style: solid
  border-color: #00000008
  border-width: 1px
</style>