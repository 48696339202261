import Vue from 'vue'

Vue.filter('siteFullName', function(site) {
  if (!site) return ' - '
  var name = ''
  if (site.code_name) {
    name = site.code_name
  }
  if (site.name) {
    if (name) {
      name += ' '
    }
    name += site.name
  }
  return name
})

Vue.filter('codeName', function (site) {
  if (!site) { return '' }
  return site.code_name
})

Vue.filter('location', function (record) {
  if (!record) { return null }
  var str = ''
  if (record.address) {
    str = record.address
  }
  if (record.city) {
    if (str) {
      str += ', '
    }
    str += record.city
  }
  if (record.speed){
    str += ' (' + record.speed + ' km/h)'
  }
  return str
})