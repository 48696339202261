<template>
  <div>
    <dialog-card title="Edytuj komentarz" :actions="actions" :handler="handlerClick" ref="card">
      <v-text-field ref="input" v-model="editedValue.message" hide-details="true" label="Komentarz" class="body-1 py-2"
        @keyup.enter.stop="onEnter"></v-text-field>
      <v-textarea label="Szczegóły" v-model="editedValue.comment"  class="body-1 py-2 mt-2" hide-details="true"
          rows="1" auto-grow @keydown.enter.exact.prevent="onEnter"
          @keydown.enter.shift.exact.prevent="editedValue.comment += '\n'">
        </v-textarea>
    </dialog-card>
  </div>
</template>

<script>

export default {
  name: "EditCommentDialog",
  props: {
    model: {
      default: () => (
        {
          message: "ss",
          comment: "null",
        }),
    }
  },
  data: () => ({
    editedValue: {},
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
  }),
  beforeMount() {
    this.editedValue = {
      message: this.model.message,
      comment: this.model.comment
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.input.focus()
    }, 100)
  },
  methods: {
    submit(value) {
      this.$emit("submit", value)
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      if (action.key) {
        this.model.message = this.editedValue.message
        this.model.comment = this.editedValue.comment
      }
      this.$emit('submit', action.key ? this.model : action.key)
    }
  }
}
</script>
