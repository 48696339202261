<template>
  <v-card outlined class="py-4" :class="{ 'red lighten-4': hasError }" id="settings" v-bind="$attrs"
    v-on="{ ...$on, ...$listeners }">
    <v-row dense class="no-gutters" justify="center">
      <user-avatar :user="patrol" small size="24" :badgeVisible="patrol.tasks.length > 0" :badgeColor="badgeColor"
        :badgeValue="patrol.tasks.length"></user-avatar>
    </v-row>

    <v-row dense class="no-gutters" justify="center">
      <router-link v-if="task != null" class="text-decoration-none"
        :to="{ name: 'Event', params: { id: task.event.id } }">
        <task-status-icon v-if="task != null" :status="status" :stats="stats" class="mr-2"></task-status-icon>
      </router-link>
      <task-status-icon v-if="task == null" :status="free" :stats="stats" class="mr-2"></task-status-icon>
    </v-row>
    <v-row v-if="task != null" dense class="no-gutters" justify="center">
      <router-link class="text-decoration-none" :to="{ name: 'Site', params: { siteId: site.id, site: site } }">
        <span class="mx-2 details--text text-caption" align="center">
          {{ site | codeName }}
        </span>
      </router-link>
    </v-row>
  </v-card>
</template>

<script>

import { Labels } from "@/store/modules/task"
import { LabelId, isOnTheTask } from "../store/modules/task"
import { parseJSON } from 'date-fns'

export default {
  name: 'MaterialSmallPatrolCard',

  inheritAttrs: false,

  props: {
    patrol: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    free: {
      label_id: LabelId.IDLE,
      details: {}
    }
  }),

  methods: {
    statusName() {
      return Labels.get(this.task.details.status.label_id).title
    },
    isSameDay() {
      const task = this.task
      return this.$options.filters.isSameDay(task.modified_at)
    },
    showMenu() {
      this.$emit('click', this.patrol)
    },
  },
  computed: {
    badgeColor() {
      if (this.hasError) {
        return 'error'
      }
      return 'success'
    },
    hasError() {
      const status = this.status
      if (status == null) {
        return false
      }
      const alertTime = Labels.get(status.label_id).alertTime
      if (!alertTime) {
        return false
      }
      const age = (Date.now() - parseJSON(status.created_at)) / 1000
      return age > alertTime
    },
    task() {
      if (this.patrol?.tasks?.length == 0) {
        return null
      }
      for (const task of this.patrol.tasks) {
        if (isOnTheTask(task)){
          return task
        }
      }
      return this.patrol.tasks[0]
    },
    lastLocation() {
      return this.patrol?.gps?.last
    },
    site() {
      return this.task?.event.site
    },
    stats() {
      if (this.patrol?.tasks?.length === 0) {
        return null
      }
      return this.task?.details?.stats
    },
    status() {
      if (this.patrol?.tasks?.length === 0) {
        return null
      }
      return this.task?.details?.status
    },
    title() {
      return this.$options.filters.userName(this.patrol)
    },
    centered() {
      return false
    },
  },
}
</script>

<style lang="sass">
  .v-card.v-card--material.v-card--material-reveal
    > .v-card__title
      > .v-card--material__title
        flex: 1 1 auto
        padding-left: 0 !important
        padding-top: 8px
        word-break: break-word

      > .v-card--material__sheet
        z-index: 1

    &.v-card--material-reveal--hover
      > .v-card__title > .v-sheet
        transform: translateY(-48px)

    .v-card--material-reveal__actions
      text-align: center

      .v-icon
        font-size: 1.125rem

    .blink_me
       animation: blinker 1s linear infinite
    @keyframes blinker
      50%
        opacity: 0
</style>

