<template>
      <div class="text-center">

        <v-menu bottom offset-y>

          <template v-slot:activator="{ on }">
            <div class="split-btn">
              <v-btn rounded color="primary" dark class="main-btn">Rounded Button</v-btn>
              <v-btn  v-on="on" rounded color="primary" dark class="actions-btn"><v-icon left>mdi-menu-down</v-icon></v-btn>
            </div>
          </template>

          <v-list>
            <v-list-item
            v-for="(item, index) in items"
            :key="index"
            >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
</template>
<script>

export default {
  name: 'SplitBtn',
  data: () => ({
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      offset: true,
    })
}
</script>

<style>
.main-btn{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 2px !important;
}
.actions-btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 !important;
  min-width: 35px !important;
  margin-left: -3.5px;
}
.split-btn{
  display: inline-block;
}
</style>