<template>
  <v-row class="d-flex">
    <v-col cols="10">
      {{ event.code | codeAsString }}
    </v-col>
    <v-dialog v-model="dialog" persistent max-width="1100px">
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              v-if="canEdit"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              class="mx-2"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edytuj</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <event-editor ref="eventEditor" :event="event" :expert="true" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelEdit">
            Anuluj
          </v-btn>
          <v-btn color="blue darken-1" text @click="applyEdit">
            Zapisz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import EventService from '@/services/event.service'

export default {
  name: 'EventHeader',

  computed: {
    eventDate: function() {
      if (this.event.details.date) {
        return this.event.details.date
      } else {
        return this.event.created_at
      }
    },
    canEdit() {
      return Boolean(this.editable)
    }
  },
  data: () => ({
    dialog: false,
  }),
  props: {
    event: null,
    editable: Boolean
  },
  methods: {
    cancelEdit() {
      this.dialog = false
      this.$refs.eventEditor.reset()
    },
    applyEdit() {
      this.dialog = false
      this.$refs.eventEditor.update()
      console.log('updated', this.event)
      EventService.update({
        id: this.event.id,
        code_id: this.event.code.id,
        site_id: this.event.site.id,
        source: this.event.source,
        details: this.event.details,
      })
        .then(() => this.$dialog.notify.info('Zdarzenie zaktualizowane'))
        .catch((error) => this.$dialog.notify.error('Błąd ' + error))
    },
  }
}
</script>