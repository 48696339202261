<template>
  <v-row dense align="center" class="pa-0">
    <v-col cols="auto" v-if="task.subtasks == null || task.subtasks.length == 0">
      <task-status-icon
        :status="task.details.status"
        :stats="task.details.stats"
      ></task-status-icon>
    </v-col>
    <v-col v-if="visibleLabel" grow>
      <div class="mx-2 flex-grow-0" align="center">
        <div>{{ statusName(task) }}</div>
        <div class="details--text">
          {{ task.modified_at | smartTimeFormat }}
          <!-- <span v-if="isSameDay(task)">({{ task.modified_at | formatDistanceWithPrefix }})</span> -->
        </div>
      </div>
    </v-col>
    <v-menu open-on-hover open-delay="500" top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-col
          v-bind="attrs"
          v-on="on"
          v-for="subtask of task.subtasks"
          :key="subtask.id"
        >
          <task-status-icon
            :status="subtask.details.status"
            :stats="subtask.details.stats"
            disabled
          />
        </v-col>
      </template>
      <v-card>
        <v-col cols="12" v-for="subtask of task.subtasks" :key="subtask.id">
          <v-card flat>
            <v-card-text>
              <v-row align="center" justify="center">
                <!-- <span>
                  <task-times :task="task"></task-times>
                </span> -->
                <user-avatar
                  v-if="subtask.assignees.length > 0"
                  :user="subtask.assignees[0].user"
                />
                <task-status-icon
                  large
                  :status="subtask.details.status"
                  :stats="subtask.details.stats"
                ></task-status-icon>
                <div class="mx-2 flex-grow-0 details--text" align="center">
                  <div>{{ statusName(subtask) }}</div>
                  <div class="details--text">
                    {{ subtask.modified_at | smartTimeFormat }} <span v-if="isSameDay(subtask)">({{ subtask.modified_at | formatDistanceWithPrefix }})</span>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-card>
    </v-menu>
  </v-row>
</template>

<script>
import { Labels } from "@/store/modules/task"

export default {
  name: "TaskStatusAll",
  props: {
    task: {
      type: Object,
      default: () => null
    },
    visibleLabel: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: "center"
    }
  },
  computed: {},
  methods: {
    statusName(subtask) {
      return Labels.get(subtask.details.status.label_id).title
    },
    isSameDay(subtask) {
      return this.$options.filters.isSameDay(subtask.modified_at)
    }
  }
}
</script>