<template>
  <div>
    <v-alert v-for="(note, i) in notes" :key="i"  prominent text color="red">
        <note :note="note" deletable :editable="false" @deleted="deleteNote"></note>
    </v-alert>
  </div>
</template>

<script>

export default {
  name: 'SiteNotes',

  beforeMount() {},
  data: () => ({}),
  props: {
    notes: {
      type: Array,
      default: null,
    }
  },
  methods: {
    deleteNote(note) {
      this.notes.splice(this.notes.findIndex(n => n.id == note.id), 1)
    },
  },
  watch: {},
}
</script>
