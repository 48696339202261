<template>
  <v-tooltip bottom color="primary">
    <template v-slot:activator="{ on, attrs1 }">
      <router-link :is="inactive ? 'span' : 'router-link'" class="text-decoration-none ma-0 pa-0"
        :to="{ name: 'Site', params: { siteId: siteId, site: site } }">
        <!-- TODO warning: [vue-router] missing param for named route "Site": Expected "siteId" to be defined -->
        <span class="ma-0 pa-0" v-bind="attrs1" v-on="on" :align="align">
          <v-icon v-if="hasIcon" class="mr-3">mdi-shield-home-outline</v-icon>
          <span class="text-subtitle-2" :class="font">{{ site.code_name }}</span>
          <span class="text-subtitle-2 ml-3" :class="font">{{ site.name }}</span>
          <chip-blocked class="ml-2" v-if="!site.is_active"></chip-blocked>
          <span v-if="address">
          <br>
          <span class="text-caption"> {{ site.city }} {{ site.district }}</span>
          <span class="text-caption"> {{ site.address }}</span>
          </span>
        </span>

      </router-link>
    </template>
    <div>{{ site.city }} {{ site.district }} </div>
    <div>{{ site.address }}</div>
    <div>Hasło: {{ site.password }}</div>
  </v-tooltip>
</template>

<script>
export default {
  name: "SiteLink",
  computed: {
    siteId() {
      return this.site?.id ?? 0
    },
    align() {
      return this.$attrs.align
    },
    colClasses() {
      if (this.hasDense) {
        return "pa-0"
      } else {
        return ""
      }
    },
    font() {
      if (this.bold) {
        return "font-weight-black"
      } else {
        return ""
      }
    },
    tags: function () {
      if (this.site && this.site.tags) {
        return this.site.tags.split(",")
      } else {
        return []
      }
    },
    hasIcon() {
      return Boolean(this.dense)
    }
  },
  props: {
    site: null,
    icon: Boolean,
    bold: Boolean,
    inactive: Boolean,
    address: Boolean,
  }
}
</script>