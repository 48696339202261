<template>
  <dialog-card title="Wyślij patrol" :actions="actions" :handler="handlerClick" ref="card">
    <v-card-text primary-title>
      <users-select :dense="false" :outlined="false" autofocus :user="model.selected" @user-selected="patrolSelected"
        label="user.patrol" :users="fetchPatrols()" @keyup.alt.enter.native="onEnter"></users-select>
      <v-text-field ref="description" v-model="model.description" class="mt-2" hide-details="true"
        :label="$t('patrolTask.description')" @keydown.enter="onEnter"></v-text-field>
    </v-card-text>
    <v-checkbox v-model="canClose" class="mr-3" label="Patrol może odjechać bez pozwolenia"></v-checkbox>
  </dialog-card>
</template>

<script>

import UserService from "@/services/user.service"

export default {
  name: "SendPatrolCard",
  data: () => ({
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
    model: {
      selected: null,
      description: null,
      canClose: false
    }
  }),
  mounted() {
  },
  props: {
  },
  methods: {
    fetchPatrols() {
      return UserService.fetchPatrols().then(data => data.results)
    },
    patrolSelected(value) {
      this.model.selected = value
      if (this.model.selected) {
        this.$refs.description.focus()
      }
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      this.$emit('submit', action.key ? this.model : action.key)
    }
  },
};
</script>
<style>
/* .vuedl-layout__closeBtn {
  color: white;
} */

/* .v-application--is-ltr .vuedl-layout__closeBtn {
  right: 14px;
}

.v-application--is-rtl .vuedl-layout__closeBtn {
  left: 14px;
}

.vuedl-layout__closeBtn:hover {
  opacity: 1;
} */
</style>
