<template>
  <router-link
    class="text-decoration-none font-weight-medium d-flex"
    target="_blank"
    :to="{ name: 'Site', params: { siteId: site.id, site: site } }"
  >
    <v-chip class="mx-1" small v-for="(tag, index) of tags" :key="index">
      {{ tag }}
    </v-chip>
    <v-chip
      class="mx-1"
      color="red"
      text-color="white"
      small
      v-if="!site.is_active"
    >
      Zablokowany
    </v-chip>
  </router-link>
</template>

<script>
export default {
  name: "SiteChips",

  computed: {
    tags: function() {
      if (this.site && this.site.tags) {
        return this.site.tags.split(",")
      } else {
        return []
      }
    }
  },
  props: {
    site: null
  }
}
</script>