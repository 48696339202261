<template>
  <div>
    <dialog-card :title="title" :actions="actions" :handler="handlerClick" ref="card">
      <v-text-field v-model="value" :label="$t('task.stats.travel.time')" type="datetime-local"
        hide-details="auto"></v-text-field>
      <!-- <VueCtkDateTimePicker v-bind="$attrs" v-model="date" no-clear-button color="#f64e65"
          label="Na miejscu" format="YYYY-MM-DDTHH:mm:ss" formatted="YYYY-MM-DDZHH:mm:ss" inline input-size="sm">
        </VueCtkDateTimePicker> -->
      <!-- <v-text-field :disabled="!valid" dense outlined v-model="travelTime"
                    :label="$t('task.stats.travel.time') + ' (sekundy)'" hide-details="auto"></v-text-field> -->
    </dialog-card>
  </div>
</template>

<script>

import { format, parseJSON } from 'date-fns';

export default {
  name: "TaskStatsDialog",

  beforeMount() {
    this.value = format(parseJSON(this.date), "yyyy-MM-dd'T'HH:mm:ss")
    console.log('mounted :>> ', this.value);
  },
  mounted() {

  },
  watch: {
    date() {
      console.log('date :>> ', this.date);
    }
  },
  data: () => ({
    title: "Podaj datę dojazdu",
    value: null,
    actions: {
      false: "Anuluj",
      true: {
        color: "success",
        text: "OK"
      },
    },
  }),
  props: {
    date: {
      type: String,
      default: null
    }
  },
  methods: {
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      this.$emit('submit', action.key ? this.value : action.key)
    }
  },
}
</script>
