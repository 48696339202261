import Vue from "vue"
import VueI18n from "vue-i18n"
import messages_pl from "@/i18n/messages/pl"
import messages_en from "@/i18n/messages/en"
import en from "vuetify/lib/locale/en"
import pl from "vuetify/lib/locale/pl"

Vue.use(VueI18n)

export default new VueI18n({
  locale: "pl",
  fallbackLocale: "pl",
  messages: {
    en: {
      ...messages_en,
      $vuetify: en,
    },
    pl: {
      ...messages_pl,
      $vuetify: pl,
    },
  },
})
