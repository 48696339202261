// Pathify
import { make } from 'vuex-pathify'
// import { initializationUserAuthentication } from '@/store/api/auth.api'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),

  init: async ({ dispatch }) => {
    // await initializationUserAuthentication()
    // console.log("init dispatch", dispatch)
    dispatch('user/load', null, { root: true })
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
