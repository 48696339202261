<template>
    <v-card-actions>
      <v-spacer />
      <slot />
    </v-card-actions>
</template>

<script>
export default {
  name: "AppCardActions"
}
</script>
