<template>
  <v-tooltip bottom v-if="status" :disabled="time == null">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs" v-on="on"
        :color="color"
        label
        :outlined="!isDone"
        :text-color="textColor"
        :large="large"
        :small="small"
      >
        <v-icon class="mr-2" color="warning" v-if="isPaused">
          mdi-pause
        </v-icon>
        <v-icon class="mr-2" color="error" v-if="isProblems">
          mdi-alert-decagram-outline
        </v-icon>
        <v-icon class="mr-2" :color="textColor" dark small  v-if="icon">
          {{ icon }}
        </v-icon>
        {{ message }}
      </v-chip>
    </template>
    <span>Zmiana statusu: {{ time | smartTimeFormat }}</span>
  </v-tooltip>
</template>

<script>
import { Labels } from "@/store/modules/task"

export default {
  name: "TaskStatusChip",

  beforeMount() {},
  data: () => ({}),
  props: {
    status: {
      type: Object,
      default: null
    },
    large: Boolean,
    small: Boolean
  },
  computed: {
    isPaused() {
      return this.status.paused
    },
    isProblems() {
      return this.status.problems
    },
    isDone() {
      return this.status.progress == 100
    },
    textColor() {
      return this.isDone ? "white" : this.color
    },
    color() {
      return Labels.get(this.status.label_id).color
    },
    message() {
      if (this.isProblems) {
        return this.status.problems
      }
      return this.title
    },
    title() {
      return Labels.get(this.status.label_id).title
    },
    time() {
      return this.status?.created_at ?? null
    },
    icon() {
      return Labels.get(this.status.label_id).icon
    },
    progress() {
      return this.status.progress
    }
  },
  methods: {},
  watch: {}
}
</script>
