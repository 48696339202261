<template>
  <dialog-card title="Odwołaj i zakończ" :actions="actions" :handler="handlerClick" ref="card">
    <users-combo autofocus label="Użytkownik" :dense="false" :outlined="false" :users="users" @user-selected="usersChanged"
          :multiple="false" @keyup.alt.enter.native="onEnter"></users-combo>
    <v-card-text class="py-0">
      <v-text-field class="mt-2" label="Komentarz końcowy" v-model="result.message" @keyup.enter.stop="onEnter">
      </v-text-field>
      <v-textarea class="mt-2" label="Dodatkowy komentarz" v-model="result.comment" rows="1" auto-grow
        @keydown.enter.exact.prevent="onEnter" @keydown.enter.shift.exact.prevent="result.comment += '\n'">
      </v-textarea>
    </v-card-text>
    <!-- <user-info :routerLink="false" :user="selected"></user-info> -->
  </dialog-card>
</template>

<script>
import _ from 'lodash'

export default {
  name: "CancelTaskDialog",
  data: () => ({
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
    result: {
      user: null,
      message: null,
      comment: null
    }
  }),
  mounted() {
    this.result.message = this.message
    this.result.comment = this.comment
    this.result.user = this.usersSelected
  },
  watch: {
  },
  computed: {
  },
  props: {
    userSelected: {
      type: Array,
      default: () => null
    },
    users: {
      type: Promise,
      default: () => new Promise(resolve => resolve([]))
    },
    message: {
      type: String,
      default: () => null
    },
    comment: {
      type: String,
      default: () => null
    }

  },
  methods: {
    usersChanged(value) {
      if (_.isString(value)) {
        value = { id: 0, full_name: value }
      }
      this.result.user = value
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      this.$emit('submit', action.key ? this.result : action.key)
    }
  },
};
</script>
