<template>
  <v-row dense class="mt-4  mb-4">
    <!-- <v-col class="pb-0">Zdarzenie</v-col> -->
    <field-label v-if="label != null" class="ml-2">{{ label }}</field-label>
    <v-col cols="12">
      <slot></slot>
    </v-col>
    <field-hint v-if="hint != null" class="ml-2">{{ hint }}</field-hint>
  </v-row>
</template>

<script>
export default {
  name: "form-field",
  props: {
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    }
  },
  data: () => ({})
}
</script>