<template>
  <!-- <material-card flat outlined class="flex-grow-1" color="white"> -->
    <!-- <template #heading>
      Groupy
    </template>
    <template #title>
    </template> -->
    <v-container>
      Grupy:
    <v-chip
      class="mx-1"
      large
      v-for="(group, index) of user.groups"
      :key="index"
      close
      close-icon="mdi-close"
      @click:close="remove(group)"
    >
      {{ groupName(group) }}
    </v-chip>
    <v-btn
      class="mx-2"
      fab
      dark
      color="indigo"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Dropdown
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    </v-container>
  <!-- </material-card> -->
</template>

<script>
export default {
  components: {},
  name: "UserGroupsEditor",

  beforeMount() {},
  data: () => ({
    value: null
  }),
  props: {
    user: {
      type: Object,
      default: null
    }
  },
  computed: {
    // travelTime() {
    //   return this.task?.details?.stats?.travel_time
    // },
  },
  methods: {
    groupName(name) {
      if (name == "_assistant_dispatcher") {
        return this.$t("user.assistant.dispatcher")
      }
      if (name == "_patrolman") {
        return this.$t("user.patrolman")
      }
      return name
    },
    remove(group) {
      console.log('this.user.groups :>> ', this.user.groups, group)
      const index = this.user.groups.indexOf(group)
      if (index > -1) {
        this.user.groups.splice(index, 1)
      }
      console.log('this.user.groups :>> ', this.user.groups, group, index)
      // this.user.groups.remove(group)
    }
    // onTheWay() {
    //   const status = { label_id: LabelId.ON_THE_WAY, progress: 50 }
    //   TaskService.setStatus(this.siteId, this.task.id, status).then(res =>
    //     this.$emit("update:task", res.data)
    //   )
    // },
    // async setPayment() {
    //   let token = 0
    //   if (this.task.details.cost) {
    //     token = this.task.details.cost.token
    //   }
    //   const dialogInstance = await this.$dialog.show(paidCard, { token: token })
    //   dialogInstance.waitForResult = true
    //   dialogInstance.wait().then(result => {
    //     if (result) {
    //       TaskService.setCost(this.siteId, this.task.id, result).then(res => {
    //         this.$emit("update:task", res.data)
    //       })
    //     }
    //   })
    // }
  },
  watch: {}
}
</script>
