<template>
  <v-card>
    <!-- <v-img src="@/assets/notification.jpg" aspect-ratio="2.75"></v-img> -->
    <v-card-title v-if="title">
      {{ title }}
    </v-card-title>
    <v-card-subtitle v-if="subtitle">
      {{ subtitle }}
    </v-card-subtitle>
    <slot />
    <app-card-actions>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <cancel-btn @click="cancelClick" v-on="on" />
        </template>
        <span>'Esc'</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <apply-btn @click="saveClick" v-on="on" :disabled="model == null" />
        </template>
        <span>'Ctrl+Enter'</span>
      </v-tooltip>
    </app-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AppDialog",
  data: () => ({}),
  mounted() {
    document.addEventListener("keydown", this.onKeydown)
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeydown)
  },
  computed: {},
  props: {
    title: null,
    subtitle: null,
    image: () => "@/assets/notification.jpg",
    model: null
  },
  methods: {
    onKeydown(e) {
      if (e.code == "Enter" && e.ctrlKey == true) {
        console.log(e)
        this.saveClick()
      }
    },
    cancelClick() {
      this.$emit("submit", null)
    },
    saveClick() {
      if (this.model == null) {
        return
      }
      this.$emit("submit", this.model)
    }
  }
}
</script>
