<template>
  <v-card v-bind="$attrs">
    <v-card-title>{{ patrol | userName }}
    </v-card-title>
    <v-card-text>
      <v-row v-for="(item, id) in workingTimes" :key="id" align="center">
        <div class="pa-3">
          {{ item.user | userName }}
          <v-chip class="mx-2 orange lighten-3" x-small v-if="isWorking(item)"
            >zakończył pracę</v-chip
          >
          <v-chip class="mx-2" x-small>{{ item | workingTime }}</v-chip>
          <v-chip v-if="item.user.bio" class="mx-2 blue lighten-4" x-small>{{ item.user.bio }}</v-chip>
        </div>
        <v-spacer></v-spacer>
        <div class="mx-2">{{ item | startedAt }} - {{ item | finishedAt }}</div>
        <v-btn
          class="ma-2"
          v-if="!readOnly"
          icon
          @click="deleteTimeWorking(item, id)"
          ><v-icon small>mdi-delete</v-icon></v-btn
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { defaultLocale } from "@/filters/date"
import {
  formatDistanceStrict,
  parseJSON,
  isSameDay,
  isBefore
} from "date-fns"

export default {
  name: "PatrolWorkingTimeCard",
  props: {
    patrol: {
      type: Object,
      default: () => {}
    },
    workingTimes: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {

  },
  data: () => ({
  }),
  watch: {

  },
  filters: {
    startedAt(value) {
      if (!value) return ""
      const started = parseJSON(value.started_at)
      const finished = parseJSON(value.finished_at)
      if (isSameDay(started, finished)) {
        return started.toLocaleString(defaultLocale, {
          timeStyle: "short"
        })
      } else {
        return started.toLocaleString(defaultLocale, {
          // timeStyle: 'short',
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        })
      }
    },
    finishedAt(value) {
      if (!value) return ""
      const started = parseJSON(value.started_at)
      const finished = parseJSON(value.finished_at)
      if (isSameDay(started, finished)) {
        return finished.toLocaleString(defaultLocale, {
          timeStyle: "short"
        })
      } else {
        return finished.toLocaleString(defaultLocale, {
          // timeStyle: 'short',
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        })
      }
    },
    workingTime(value) {
      if (!value) return ""
      return formatDistanceStrict(
        parseJSON(value.started_at),
        parseJSON(value.finished_at),
        {
          addSuffix: false,
          locale: defaultLocale(),
          roundingMethod: "round",
          unit: "hour"
        }
      )
    }
  },
  methods: {
    isWorking(item) {
      return isBefore(parseJSON(item.finished_at), new Date())
    },
    deleteTimeWorking(item, id) {
      this.$emit("deleteWorkingTime", this.workingTimes, id)
    }
  }
}
</script>