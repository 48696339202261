<template>
  <dialog-card title="Zakończ" :actions="actions" :handler="handlerClick" titleClass="pb-0" ref="card">
    <v-card-text class="pt-0 px-0">
      {{ siteName }}
    </v-card-text>
    <v-card-text class="py-0">
          <v-checkbox v-model="result.note" label="Pozostaw notatkę" hide-details></v-checkbox>
          <v-select label="Rodzaj notatki" v-if="result.note" v-model="selectedNote" :items="notes" :item-text="(item) => item.name" return-object ></v-select>
          <VueCtkDateTimePicker
                      v-bind="$attrs"
                      v-if="result.note"
                      v-model="expiresNoteAt"
                      color="#f64e65"
                      :hint="selectedNote.endLabel"
                      :minDate="minExpiresAt"
                      label="Do odwołania"
                      format="YYYY-MM-DD HH:mm"
                      formatted="dddd, DD MMMM YYYY HH:mm"
                    ></VueCtkDateTimePicker>
      <v-divider class="my-3"></v-divider>
      <v-text-field autofocus class="mt-2" label="Komentarz końcowy" v-model="result.message" @keyup.enter.stop="onEnter">
      </v-text-field>
      <v-textarea class="mt-2" label="Dodatkowy komentarz ( Shift+Enter : nowa linia )" v-model="result.comment" rows="1"
        auto-grow @keydown.enter.exact.prevent="onEnter" @keydown.enter.shift.exact.prevent="result.comment += '\n'">
      </v-textarea>
    </v-card-text>
    <v-divider class="my-3"></v-divider>
    <v-card-title>
      Opłata
    </v-card-title>
    <v-row v-for="(p, index) in result.payments" :key="p.task.id">
      <v-col cols="2">
        <user-avatar class="mt-2" :user="firstAssigneeUser(p.task)" />
      </v-col>
      <v-col cols="8">
        <payments-select :token="p.token" @token-changed="onTokenChanged(index, $event)"></payments-select>
      </v-col>
    </v-row>
  </dialog-card>
</template>

<script>
// import _ from 'lodash'
import { UserTaskType } from '@/store/modules/task'
import { Note } from "@/store/modules/note";
import { addHours } from "date-fns";

export default {
  name: "CloseTaskDialog",
  data: () => ({
    actions: {
      false: "Anuluj",
      true: {
        color: "success",
        text: "OK"
      },
    },
    selectedNote: {
      endLabel: "Zakończ",
    },
    expiresNoteAt: null,
    notes: Note.all(),
    result: {
      payments: [],
      message: null,
      comment: null
    }
  }),
  mounted() {
    this.result.message = this.message
    this.result.comment = this.comment
    this.result.payments = this.patrolTasks.map((task) => {
      console.log('mounted :>> ', this.notes);
      return {
        task: task,
        token: task.details?.cost?.token
      };
    });
  },
  watch: {
    "results.payments.token": {
      handler: function (val, oldVal) {
        console.log("token", val, oldVal);
      },
      deep: true,
    },
    selectedNote() {
      if (this.selectedNote.duration == null) {
        this.expiresNoteAt = null;
      } else {
        this.expiresNoteAt = this.$options.filters?.localDateTime(addHours(new Date(), this.selectedNote.duration))
      }
    },
  },
  computed: {
    siteName() {
      return this.$options.filters.siteFullName(this.event?.site);
    },
    patrolTasks() {
      return this.event.tasks.filter((task) => {
        return task.task_type == UserTaskType.PATROL;
      });
    },
    minExpiresAt() {
      return this.$options.filters?.localDateTime(addHours(new Date(), 1))
    },
  },
  props: {
    event: {
      type: Object,
      default: () => null
    },
    message: {
      type: String,
      default: () => null
    },
    comment: {
      type: String,
      default: () => null
    }
  },
  methods: {
    onTokenChanged(index, value) {
      console.log("onTokenChanged", index, value);
      this.result.payments[index].token = value;
    },
    firstAssigneeUser(task) {
      if (!task?.assignees?.length) {
        return null;
      }
      return task.assignees[0].user;
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      if (this.result.note) {
        this.result.note = this.selectedNote
        this.result.note.expiresAt = this.expiresNoteAt
      }
      this.$emit('submit', action.key ? this.result : action.key)
    }
  },
};
</script>
