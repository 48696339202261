<template>
  <v-tooltip bottom v-if="user">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bordered overlap :content="badgeValue" :value="badgeVisible" :color="badgeColor" offset-x="25">
        <v-avatar v-bind="attrs" v-on="on" class="mp-avatar mx-3" :size="size" justify="center" v-if="user">
          <v-img :src="user.avatar_url"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <span>{{ user | userName }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    user: {
      type: Object,
      default: null
    },
    small: Boolean,
    large: Boolean,
    badgeColor: {
      type: String,
      default: "primary"
    },
    badgeValue: {
      type: Number,
      default: null
    },
    badgeVisible: {
      type: Boolean,
      default: false
    }

  },
  computed: {
    size() {
      if (this.large) {
        return "58px"
      } else if (this.small) {
        return "38px"
      } else {
        return "48px"
      }
    }
  }
}
</script>