<template>
  <v-card v-bind="$attrs" v-on="$listeners" class="">
    <v-card-title class="text-h5 teal lighten-3 lighten-2">

      <user-avatar :user="patrol" class="mr-2" small size="32"></user-avatar>
      {{ title }}
      <v-spacer></v-spacer>

      <v-col>
        <div class="text-body-1">
          {{ lastAddress }}
        </div>
        <div class="text-body-1">
          {{ lastSpeed }} km/h
        </div>
        <div class="text-caption">
          {{ lastDataTime | formatDistanceWithPrefix }}
        </div>
      </v-col>
      <v-btn icon v-on="$listeners" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-4">

      <router-link v-for="task of patrol.tasks" v-bind:key="task.id" class="text-decoration-none"
        :to="{ name: 'Event', params: { id: task.event.id } }">
        <task-row :task="task"></task-row>
        <!-- <event-row :event="task.event"></event-row> -->
      </router-link>
    </v-card-text>
    <div>
      <slot />
    </div>
    <v-row dense>
      <gmaps-map :options="map.options" style="height: 500px;" ref="map" v-on:mounted="mountedMap">
        <gmaps-marker v-for="(item, i) in map.markers" :key="i" :options="item.options" />
      </gmaps-map>
    </v-row>
  </v-card>
</template>

<script>

import { gmaps, gmapsMap, gmapsMarker } from "x5-gmaps"
import { LabelId, Labels, isOnTheTask } from "@/store/modules/task"

export default {
  name: 'MaterialPatrolCard',

  inheritAttrs: false,
  components: {
    gmapsMap,
    gmapsMarker,
  },
  props: {
    patrol: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    free: {
      label_id: LabelId.IDLE,
      details: {}
    },
    google: {
      maps: null,
      mapObject: null
    },
    autocenter: true,
    followButton: null,
    map: {
      options: {
        // zoom: 5,
        center: { lat: 0, lng: 0 }
      },
      markers: [],
      actionIcon: "mdi-tag",
      actionText: "",
      color: "primary",
      title: "",
      subtitle: ""
    }
  }),
  watch: {
    patrol: {
      handler() {
        this.setBounds()
      },
      deep: true
    },
    autocenter(value) {
      if (value) {
        this.setBounds()
        this.followButton.textContent = "Podążąj"
        this.followButton.title = 'Kliknij aby zablokować pozycję mapy';
      } else {
        this.followButton.textContent = "Nie podążaj"
        this.followButton.title = 'Kliknij aby śledzić pozycję patrolu';
      }
    }
  },
  mounted() {
    gmaps().then((googleMaps) => {
      this.google.maps = googleMaps
      this.setBounds()
    })
  },
  methods: {
    close() {
      this.$emit("close", {});
    },
    statusName() {
      return Labels.get(this.task.details.status.label_id).title
    },
    isSameDay() {
      const task = this.task
      return this.$options.filters.isSameDay(task.modified_at)
    },
    mountedMap(map) {
      this.google.mapObject = map
      // Create the DIV to hold the control.
      // const centerControlDiv = document.createElement('div');
      // Create the control.
      this.followButton = this.createFollowControl();
      // Append the control to the DIV.
      // centerControlDiv.appendChild(centerControl);
      map.controls[1].push(this.followButton)
      // map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);

      map.addListener("dragstart", () => {
        if (this.autocenter) {
          this.autocenter = false
        }
      });

      this.setBounds()
    },
    setBounds() {
      if (!this.google.mapObject || !this.google.maps) {
        return
      }
      let markers = []
      if (this.lastLocation) {
        markers.push({
          options: {
            position: {
              lng: this.patrol.gps.last.location.coordinates[0],
              lat: this.patrol.gps.last.location.coordinates[1]
            },
            icon: {
              url: require(`@/assets/pin(circle-x-large-004d40)-icon(awesome-car-medium)-@2x-2.png`),
              // url: 'https://api.geoapify.com/v1/icon?type=awesome&color=%2352b74c&size=medium&icon=tree&noWhiteCircle=false&scaleFactor=1&apiKey=210dd8ac50f943b78200b795164a5055',
              scaledSize: { width: 40, height: 40 },
              anchor: { x: 20, y: 20 }
            }
            //  icon: "{url:'../assets/location.png'}",
            // icon: {
            //   path: google.maps.SymbolPath.CIRCLE,
            //   scale: 8,
            //   fillColor: "#00BFA5",
            //   fillOpacity: 1,
            //   strokeWeight: 0,
            // },
          },
        })
      }

      for (let task of this.patrol.tasks) {
        // if (!this.map.options.center) {

        // }
        markers.push({
          options: {
            position: {
              lng: task.event.site.location.coordinates[0],
              lat: task.event.site.location.coordinates[1]
            },
            title: task.event.site.code_name,
            draggable: true,
            icon: {
              url: isOnTheTask(task) ? require(`@/assets/pin(circle-x-large-004d40)-icon(awesome-home-medium)-@2x-2.png`) : require(`@/assets/pin(circle-x-large-004d40)-icon(awesome-home-medium)-@2x.png`),
              scaledSize: { width: 40, height: 40 },
              anchor: { x: 20, y: 20 }
            }
            // icon: {
            //   path: google.maps.SymbolPath.CIRCLE,
            //   scale: 8,
            //   fillColor: "#00BFA5",
            //   fillOpacity: 1,
            //   strokeWeight: 0,
            // },
          },
        })
      }
      this.map.markers = markers
      if (this.map.markers.length > 0) {
        // if this.map.markers.length > 1
        // const bounds = new this.google.maps.LatLngBounds();
        // this.map.markers.forEach(marker => {
        //   bounds.extend(marker.options.position);
        // });
        // this.google.mapObject.fitBounds(bounds);
        if (this.autocenter) {
          this.map.options.center = this.map.markers[0].options.position
        }
      }
    },
    createFollowControl() {
      const controlButton = document.createElement('button');

      // Set CSS for the control.
      controlButton.style.backgroundColor = '#fff';
      controlButton.style.border = '2px solid #fff';
      controlButton.style.borderRadius = '3px';
      controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlButton.style.color = 'rgb(25,25,25)';
      controlButton.style.cursor = 'pointer';
      controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
      controlButton.style.fontSize = '16px';
      controlButton.style.lineHeight = '38px';
      controlButton.style.margin = '8px 0 22px';
      controlButton.style.padding = '0 5px';
      controlButton.style.textAlign = 'center';

      controlButton.textContent = 'Podążąj';
      controlButton.title = 'Kliknij aby zablokować pozycję mapy';
      controlButton.type = 'button';

      // Setup the click event listeners: simply set the map to Chicago.
      controlButton.addEventListener('click', () => {
        this.autocenter = !this.autocenter
      });

      return controlButton;
    }
  },
  computed: {
    lastAddress() {
      return this.patrol?.gps?.last?.address
    },
    lastSpeed() {
      return this.patrol?.gps?.last?.speed
    },
    lastDataTime() {
      return this.patrol?.gps?.last?.time
    },
    lastLocation() {
      return this.patrol?.gps?.last
    },
    title() {
      return this.$options.filters.userName(this.patrol)
    },
    centered() {
      return false
    },
  },
}
</script>

<style lang="sass">
  .v-card.v-card--material.v-card--material-reveal
    > .v-card__title
      > .v-card--material__title
        flex: 1 1 auto
        padding-left: 0 !important
        padding-top: 8px
        word-break: break-word

      > .v-card--material__sheet
        z-index: 1

    &.v-card--material-reveal--hover
      > .v-card__title > .v-sheet
        transform: translateY(-48px)

    .v-card--material-reveal__actions
      text-align: center

      .v-icon
        font-size: 1.125rem
</style>
