<template>
  <validation-provider v-slot="{ errors }" rules="" name="user.combo">
    <v-combobox
      ref="user"
      :dense="dense"
      v-model="selected"
      v-bind="$attrs"
      :items="items"
      :item-text="item => $options.filters.userName(item)"
      :loading="isLoading"
      :label=label
      :hint=hint
      :outlined="outlined"
      :multiple="multiple"
      :chips="multiple"
      :deletable-chips="multiple"
      :error-messages="errors"
      return-object
      hide-details="auto"
    >
      <template v-slot:selection="data">
        {{ data.item | userName }}
      </template>
      <template slot="item" slot-scope="{ item }">
        <div>
        {{ item | userName }}
        <v-chip small v-for="tag, index in item.tags" :key="index" :color="tag.color" class="mx-2">{{ tag.title }}</v-chip>
        </div>
      </template>
    </v-combobox>
  </validation-provider>
</template>

<script>
export default {
  name: "UsersCombo",

  beforeMount() {
    this.isLoading = true
    this.users.then(data => {
      this.items = data
      this.isLoading = false
      for (var user in this.usersSelected) {
        this.items.push(user)
      }
      this.selected = this.usersSelected
    })
  },
  data: () => ({
    selected: null,
    isLoading: false,
    internalSearch: null,
    items: [],
  }),
  props: {
    label : {
      type: String,
      default: '',
    },
    hint : {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    autocommit: {
      type: Boolean,
      default: false,
    },
    searchInput: {
      default: undefined
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    users: {
      type: Promise,
      default: () => new Promise((resolve) => {
        resolve([])
      })
    },
    usersSelected: {
      type: Object,
      default: null,
    },
  },
  watch: {
    internalSearch(value) {
      this.$emit('update:searchInput', value)
    },
    users(value) {
      this.isLoading = true
      value.then(data => {
        this.items = data
        this.isLoading = false
      })
    },
    usersSelected(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("user-selected", value);
    }
  },
  methods: {
    focus() {
      this.$refs.user.focus()
    },
    remove(item) {
      const index = this.selected.indexOf(item)
      if (index >= 0) this.selected.splice(index, 1)
    },
  },
}
</script>