<template>
  <validation-provider v-slot="{ errors }" :rules="rules" name="event.site">
    <v-autocomplete
      ref="site"
      dense
      v-bind="$attrs"
      v-model="selected"
      :items="sites"
      item-value="id"
      item-text="code_name"
      :loading="isLoading"
      :label="$t('event.site')"
      :search-input.sync="search"
      outlined
      :error-messages="errors"
      return-object
      no-filter
      @keyup.delete.exact.prevent="onClear"
      auto-select-first
      hide-details="auto"
    >
      <template slot="selection" slot-scope="{ item }">
        {{ item.code_name }} - {{ item.name }}
      </template>
      <template slot="item" slot-scope="{ item }">
        {{ item.code_name }} - {{ item.name }}
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
import SiteService from "@/services/site.service";
export default {
  name: "SitesCombo",

  beforeMount() {
    if (this.site) {
      this.sites.push(this.site);
    }
    this.selected = this.site;
  },
  data: () => ({
    selected: null,
    isLoading: false,
    sites: [],
    search: null,
  }),
  props: {
    autocommit: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default: null,
    },
    rules: {
      type: String,
      default: ""
    }
  },
  watch: {
    site(value) {
      if (value != null && !this.sites.find(element => element.id == value)) {
        this.sites.push(value)
      }
      this.selected = value;
    },
    selected(value) {
      this.$emit("site-selected", value);
    },
    search(value) {
      // Lazily load input items
      if (value == null) return;
      if (value.length < 2) return;

      this.isLoading = true;
      SiteService.fetch(value)
        .then((data) => {
          return data.results
        })
        .then((sites) => {
          this.sites = sites
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => (this.isLoading = false))
    },
  },
  methods: {
    onClear() {
      if (!this.search) {
        this.selected = null
      }
    },
  }
};
</script>