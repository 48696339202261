<template>
    <div>
    <tooltip-chip v-for="(chip, index) of eventChips" :key="index" class="mr-2 mt-1" small :tooltip="chip.tooltip"
        :icon="chip.icon" :text="chip.text" :close="chip.close" @click:close="deleteCanceled(chip)">
    </tooltip-chip>

    <v-timeline v-if="task != null" dense>
        <v-hover v-slot:default="{ hover }" v-for="(item) in timeline" :key="item.id">
            <v-timeline-item color="pink" small>
                <template v-slot:icon>
                    <user-avatar v-if="item.avatar" small :user="firstAssigneeUser"></user-avatar>
                    <v-avatar v-else :color="item.color" size="32">
                        <v-icon small color="white">{{ item.icon }}</v-icon>
                    </v-avatar>
                </template>
                <template v-slot:opposite>
                    <h4 class="text-title black--text">{{ item.time | timeMedium }}</h4>
                </template>

                <v-row dense>
                    <v-col cols="9">
                        <!-- <div> {{ index }} {{ item.label.id }} </div> -->
                        <h4 class="text-title black--text">{{ item.time }}
                            <span class="text-subtitle details--text">{{ item.header }}</span>

                            <v-chip class="mx-2" :color="item.payment.color" :text-color="item.payment.textColor" small
                                v-if="item.payment">
                                <v-icon class="mr-1" :color="item.payment.textColor" small> mdi-currency-usd </v-icon>
                                {{ item.payment.short }}
                            </v-chip>
                        </h4>
                        <span class="text-subtitle-2" style="white-space: pre;">{{ item.title }}</span>
                        <div class="text-subtitle-2" style="white-space: pre;">{{ item.subtitle }}</div>
                        <tooltip-chip v-for="(chip, index) of item.chips" :key="index" class="mr-2 mt-1" small
                            :tooltip="chip.tooltip" :icon="chip.icon" :text="chip.text" :close="chip.close"
                            @click:close="deleteCanceled(chip)">
                        </tooltip-chip>
                        <v-btn v-if="item.mainAction" small color="primary" @click="item.mainAction(item)">{{ item.mainActionTitle }}</v-btn>
                    </v-col>
                    <v-col cols="3">

                        <icon-btn v-if="hover && !readOnly && item.editMethod" small :tooltip=item.editTooltip
                            icon="mdi-pencil" @click="item.editMethod(item)" />
                        <icon-btn v-if="hover && !readOnly && item.deleteMethod" small :tooltip=item.deleteTooltip
                            icon="mdi-delete" @click="item.deleteMethod(item)" />
                    </v-col>
                </v-row>
            </v-timeline-item>
        </v-hover>
    </v-timeline>
    </div>
</template>

<script>
// import EventService from '@/services/event.service'
import { Labels, LabelId } from "@/store/modules/task"
import TaskService from "@/services/task.service"
import editCommentDialog from "@/components/EditCommentDialog.vue"
import taskStatsDialog from "@/components/TaskStatsDialog.vue"
import payments from "@/store/modules/payments"
import UserAvatar from "./UserAvatar.vue"
import { parseJSON, differenceInSeconds } from 'date-fns'
import { isWaitingForPermission } from "../store/modules/task"

export default {
    components: { UserAvatar },
    name: "TaskTimeline",

    beforeMount() { },
    data: () => ({
        value: null
    }),
    props: {
        site: {
            type: null,
            default: null
        },
        task: {
            type: Object,
            default: null
        },
        event: {
            type: Object,
            default: null
        },
        readOnly: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        firstAssigneeUser() {
            if (!this.task?.assignees?.length) {
                return null
            }
            return this.task.assignees[0].user
        },
        notifiedUsers() {
            return this.task?.details?.notified ?? []
        },
        foundUsers() {
            return this.task?.details?.found ?? []
        },


        history() {
            let h = []
            for (let i of this.task?.details?.history ?? []) {
                h.push(i)
            }
            for (let n of this.notifiedUsers) {
                let item = n
                item.label_id = LabelId.NOTIFICATION
                item.user = {
                    id: item.id,
                    name: item.full_name
                }
                h.push(item)
            }
            for (let n of this.foundUsers) {
                let item = n
                item.label_id = LabelId.PERSON
                item.user = {
                    id: item.id,
                    name: item.full_name
                }
                h.push(item)
            }
            // if (!this.task) {
            //     let item = {
            //         label_id: LabelId.NEW,
            //         created_at: this.event?.created_at,
            //         full_name: this.event?.details?.created_by?.full_name
            //     }
            //     h.push(item)
            // }

            // sort by created_at
            h.sort((a, b) => {
                return new Date(a.created_at) - new Date(b.created_at)
            })
            return h
        },
        eventChips() {
            var chips = []
            if (this.eventCreated) {
                chips.push({
                    icon: 'mdi-account-plus-outline',
                    text: this.eventCreated,
                    tooltip: "Utworzył",
                    click: () => { }
                })
            }
            if (this.eventReported) {
                chips.push({
                    icon: 'mdi-account-alert-outline',
                    text: this.eventReported,
                    tooltip: "Zgłosił"
                })
            }
            return chips
        },
        eventReported() {
            return this.event?.details?.reported_by?.full_name ?? null
        },
        eventCreated() {
            return this.event?.details?.created_by?.full_name ?? null
        },
        timeline() {
            let items = []
            let problems = false
            let paused = false
            const context = this
            let allHistory = this.history
            allHistory?.forEach(function (item, index, array) {
                let isLast = index == array.length - 1
                let chips = []
                let payment = null
                if (item.label_id == LabelId.DELIVERED) { return }
                let label = Labels.get(item.label_id)
                if (!label) { return }
                let header = label.title
                let time = context.$options.filters.timeMedium(item.created_at)
                let title = (item.message ? item.message : '') + (item.comment ? '\n' + item.comment : '')
                let subtitle = ''
                let editMethod = null
                let editTooltip = null
                let deleteTooltip = null
                let deleteMethod = null

                if (item.problems) {
                    problems = true
                    title = item.problems + (item.comment ? ' - ' + item.comment : '')
                    label = Labels.get(LabelId.PROBLEMS)
                } else if (problems) {
                    problems = false
                    subtitle = Labels.get(LabelId.END_OF_PROBLEMS).title
                } else if (item.paused) {
                    paused = true
                    label = Labels.get(LabelId.PAUSED)
                } else if (paused) {
                    paused = false
                    subtitle = Labels.get(LabelId.RESUMED).title
                }
                if (label.id == LabelId.NEW) {
                    time = context.$options.filters.smartTimeFormat(item.created_at)
                } else if (label.id == LabelId.AT_LOCATION) {
                    title = context.$t('task.stats.travel.time') + ': ' + context.$options.filters.timeDuration(context.task.details.stats.travel_time)
                    editMethod = context.updateTravelTime
                    editTooltip = "Edytuj czas dojazdu"
                } else if (label.id == LabelId.COMMENT) {
                    header = item.message
                    title = item.comment
                    deleteMethod = context.deleteComment
                    deleteTooltip = "Usuń komentarz"
                    editTooltip = "Edytuj komentarz"
                    // found.push(item)
                } else if (label.id == LabelId.NOTIFICATION) {
                    // title = item.full_name
                    deleteMethod = context.deleteNotification
                    deleteTooltip = "Usuń powiadomienie"
                } else if (label.id == LabelId.PERSON) {
                    // title = item.full_name
                    deleteMethod = context.deletePerson
                    deleteTooltip = "Usuń osobę z listy"
                } else if (label.id == LabelId.CANCELED) {
                    // Odwołał:
                    for (let c of context.task?.details.canceled ?? []) {
                        chips.push({
                            icon: 'mdi-account-alert-outline',
                            text: c.full_name,
                            tooltip: "Odwołał",
                            close: true,
                            click: context.deleteCanceled,
                            source: c
                        })
                    }
                    deleteTooltip = "Usuń osobę z listy"
                }
                if (Labels.isFinal(label.id) || label.id == LabelId.COMMENT) {
                    editMethod = context.updateComment
                    editTooltip = "Edytuj szczegóły"
                }
                if (item.user) {
                    chips.push({
                        icon: 'mdi-account-outline',
                        text: context.$options.filters.userName(item.user),
                        tooltip: null,
                        close: false,
                        click: null,
                        source: null
                    })
                }
                if (isLast) {
                    payment = context.payment
                }
                items.push({
                    id: index,
                    color: label.color,
                    icon: label.icon,
                    header: header,
                    avatar: item.label_id == LabelId.NEW,
                    time: time,
                    title: title,
                    subtitle: subtitle,
                    chips: chips,
                    payment: payment,
                    editMethod: editMethod,
                    editTooltip: editTooltip,
                    deleteMethod: deleteMethod,
                    deleteTooltip: deleteTooltip,
                    source: item
                })
            })
            if (isWaitingForPermission(this.task)) {
                items.push({
                    id: 'waiting',
                    color: 'orange',
                    icon: 'mdi-clock-alert-outline',
                    header: 'Oczekiwanie na zgodę na odjazd',
                    time: context.$options.filters.timeMedium(this.task.details.created_at),
                    mainAction: context.allowToClosed, // confirm departure
                    mainActionTitle: 'Potwierdź odjazd',
                    chips: [],
                    payment: null,
                    editMethod: null,
                    editTooltip: null,
                    deleteMethod: null,
                    deleteTooltip: null,
                    source: null
                })
            }
            return items
        },
        payment() {
            return payments.findWithValue(this.task?.details?.cost?.token ?? -1)
        }
    },
    methods: {
        async updateComment(item) {
            const dialogInstance = await this.$dialog.show(editCommentDialog, { title: item.title, model: item.source })
            dialogInstance.waitForResult = true
            dialogInstance.wait().then(result => {
                if (result) {
                    TaskService.updateTask(this.site.id, this.task.id, this.task).then(res =>
                        this.$emit("update:task", res.data)
                    )
                }
            })
        },
        async allowToClosed(item) {
            console.log('allowToClose :>> ', this.site.id, this.task.id, item);
            TaskService.allowToClosedTask(this.site.id, this.task.id).then(res =>
                this.$emit("update:task", res.data)
            )
        },
        async deleteNotification(item) {
            const res = await this.$dialog.confirm({
                text: "Czy na pewno skasować?",
                title: item.title,
                actions: {
                    false: "Nie",
                    true: {
                        color: "error",
                        text: "Skasuj"
                    }
                }
            })
            if (res === true) {
                TaskService.deleteNotifiedUser(this.site.id, this.task.id, item.source).then(
                    () => {
                        // this.notifiedUsers.splice(item.index, 1)
                        // this.refresh()
                        this.$emit("update:task", res.data)
                    }
                )
            }
        },
        async deletePerson(item) {
            const res = await this.$dialog.confirm({
                text: "Czy na pewno skasować?",
                title: item.title,
                actions: {
                    false: "Nie",
                    true: {
                        color: "error",
                        text: "Skasuj"
                    }
                }
            })
            if (res === true) {
                TaskService.deleteFoundUser(this.site.id, this.task.id, item.source).then(
                    () => {
                        console.log('deleteFoundUser :>> ', item.source);
                        // this.notifiedUsers.splice(item.index, 1)
                        // this.refresh()
                        this.$emit("update:task", res.data)
                    }
                )
            }
        },
        async deleteCanceled(item) {
            let user = item.source
            console.log('deleteCanceled :>> ', item.source.full_name);
            const res = await this.$dialog.confirm({
                text: "Czy na pewno skasować?",
                title: item.title,
                actions: {
                    false: "Nie",
                    true: {
                        color: "error",
                        text: "Skasuj"
                    }
                }
            })
            if (res === true) {
                TaskService.deleteCanceledUser(this.site.id, this.task.id, user).then(
                    () => {
                        console.log('deleteCanceledUser :>> ', user);
                        // this.notifiedUsers.splice(item.index, 1)
                        // this.refresh()
                        this.$emit("update:task", res.data)
                    }
                )
            }
        },
        async deleteComment(item) {
            const res = await this.$dialog.confirm({
                text: "Czy na pewno skasować?",
                title: item.title,
                actions: {
                    false: "Nie",
                    true: {
                        color: "error",
                        text: "Skasuj"
                    }
                }
            })
            if (res === true) {
                this.task.details.history = this.task.details.history.filter(el => el != item.source);
                TaskService.updateTask(this.site.id, this.task.id, this.task).then(res =>
                    this.$emit("update:task", res.data)
                )
            }
        },
        async updateTravelTime(item) {
            console.log('updateTravelTime :>> ', item.source.created_at, item);
            const dialogInstance = await this.$dialog.show(taskStatsDialog, { date: item.source.created_at })
            dialogInstance.waitForResult = true
            dialogInstance.wait().then(result => {
                console.log('result :>> ', result);
                if (result) {
                    let stats = this.task.details.stats
                    let beginTime = parseJSON(stats.travel_started_at)
                    let valid = beginTime instanceof Date && !isNaN(beginTime)
                    if (!valid) {
                        //show error
                        this.$dialog.notify.error("Nieprawidłowa data dojazdu ")
                        return
                    }
                    let arrivalTime = new Date(result) // result is local time
                    let travelTime = differenceInSeconds(arrivalTime, beginTime)

                    if (travelTime < 0) {
                        this.$dialog.notify.error("Nieprawidłowa data dojazdu")
                        return
                    }
                    stats.travel_time = travelTime
                    stats.travel_finished_at = this.$options.filters.backendISO(arrivalTime)

                    item.source.created_at = stats.travel_finished_at
                    TaskService.updateTask(this.site.id, this.task.id, this.task).then(res =>
                        this.$emit("update:task", res.data)
                    )
                }
            })
        }
    },
    watch: {}
}
</script>
