import { make } from 'vuex-pathify'
import { fetchUser as fetchUserService } from '@/services/auth.service'
import { IN_BROWSER } from '@/util/globals'
const allTabs = [
  {
    title: 'tab.new.event',
    // icon: 'mdi-home-map-marker',
    icon: 'mdi-bell-plus-outline',
    to: '/event/new',
    access: 'dispatcher'
  },
  {
    title: 'Nowe zgłoszenie',
    // icon: 'mdi-home-map-marker',
    icon: 'mdi-note-plus-outline',
    to: '/note/new',
    access: 'dispatcher'
  },
  // {
  //   title: 'tab.dashboard',
  //   icon: 'mdi-home',
  //   to: '/dashboard',
  // },
  // {
  //   title: "tab.new.note",
  //   // icon: 'mdi-home-map-marker',
  //   icon: "mdi-note-plus",
  //   to: "/forms/new-note/",
  // },
  // {
  //   title: 'tab.new.task',
  //   // icon: 'mdi-home-map-marker',
  //   icon: 'mdi-playlist-plus',
  //   to: '/forms/new-task/',
  // },
  {
    title: 'tab.events',
    // icon: 'mdi-home-map-marker',
    // icon: 'mdi-clipboard-list-outline',
    icon: 'mdi-format-list-bulleted',
    to: '/tables/events',
  },
  {
    title: 'tab.intervention',
    // icon: 'mdi-home-map-marker',
    // icon: 'mdi-clipboard-list-outline',
    icon: 'mdi-car',
    to: '/interventions',
  },
  {
    title: 'tab.sites',
    // icon: 'mdi-home-map-marker',
    icon: 'mdi-shield-home-outline',
    to: '/sites',
  },
  {
    title: 'tab.codenames',
    // icon: 'mdi-home-map-marker',
    icon: 'mdi-pound',
    to: '/codenames',
    access: 'admin'
  },
  {
    title: 'tab.notes',
    icon: 'mdi-note',
    to: '/notes/',
  },
  {
    title: 'tab.gps',
    icon: 'mdi-home-map-marker',
    to: '/gps/',
  },
  {
    title: 'tab.working-times',
    icon: 'mdi-calendar-range-outline',
    to: '/working-times',
  },
  {
    title: 'tab.users',
    icon: 'mdi-account',
    to: '/users/',
    access: "admin"
  },
  // {
  //   title: 'Dashboard',
  //   icon: 'mdi-view-dashboard',
  //   to: '/',
  // },
  // {
  //   title: 'Pages',
  //   icon: 'mdi-image',
  //   items: [
  //     {
  //       title: 'Pricing',
  //       to: '/pages/pricing/',
  //     },
  //     {
  //       title: 'RTL Support',
  //       to: '/pages/rtl/',
  //     },
  //     {
  //       title: 'Timeline',
  //       to: '/components/timeline/',
  //     },
  //     {
  //       title: 'Login Page',
  //       to: '/pages/login/',
  //     },
  //     {
  //       title: 'Register Page',
  //       to: '/pages/register/',
  //     },
  //     {
  //       title: 'Lock Screen Page',
  //       to: '/pages/lock/',
  //     },
  //     {
  //       title: 'User Profile',
  //       to: '/components/profile/',
  //     },
  //     {
  //       title: 'Error Page',
  //       to: '/pages/error/',
  //     },
  //   ],
  // },
  // {
  //   title: 'Components',
  //   icon: 'mdi-view-comfy',
  //   items: [
  //     {
  //       title: 'Multi Level Collapse',
  //       items: [{
  //         title: 'Example',
  //       }],
  //     },
  //     {
  //       title: 'Buttons',
  //       to: '/components/buttons/',
  //     },
  //     {
  //       title: 'Grid System',
  //       to: '/components/grid/',
  //     },
  //     {
  //       title: 'Tabs',
  //       to: '/components/tabs/',
  //     },
  //     {
  //       title: 'Notifications',
  //       to: '/components/notifications/',
  //     },
  //     {
  //       title: 'Icons',
  //       to: '/components/icons/',
  //     },
  //     {
  //       title: 'Typography',
  //       to: '/components/typography/',
  //     },
  //   ],
  // },
  // {
  //   title: 'Forms',
  //   icon: 'mdi-clipboard-outline',
  //   items: [
  //     {
  //       title: 'Regular Forms',
  //       to: '/forms/regular/',
  //     },
  //     {
  //       title: 'Extended Forms',
  //       to: '/forms/extended/',
  //     },
  //     {
  //       title: 'Valiation Forms',
  //       to: '/forms/validation/',
  //     },
  //     {
  //       title: 'Wizard',
  //       to: '/forms/wizard/',
  //     },
  //   ],
  // },
  // {
  //   title: 'Tables',
  //   icon: 'mdi-grid',
  //   items: [
  //     {
  //       title: 'Regular Tables',
  //       to: '/tables/regular/',
  //     },
  //     {
  //       title: 'Extended Tables',
  //       to: '/tables/extended/',
  //     },
  //     {
  //       title: 'DataTables Tables',
  //       to: '/tables/data-tables/',
  //     }
  //   ],
  // },
  // {
  //   title: 'Maps',
  //   icon: 'mdi-map-marker',
  //   items: [
  //     {
  //       title: 'Google Maps',
  //       to: '/maps/google/',
  //     },
  //     {
  //       title: 'Fullscreen Map',
  //       to: '/maps/fullscreen/',
  //     },
  //   ],
  // },
  // {
  //   title: 'Widgets',
  //   icon: 'mdi-widgets',
  //   to: '/widgets/',
  // },
  // {
  //   title: 'Charts',
  //   icon: 'mdi-chart-timeline-variant',
  //   to: '/charts/',
  // },
  // {
  //   title: 'Calendar',
  //   icon: 'mdi-calendar-range-outline',
  //   to: '/calendar/',
  // },
]
const state = {
  fetched: false,
  profile: null,
  first_name: '',
  last_name: '',
  email: '',
  dark: false,
  drawer: {
    image: 0,
    gradient: 0,
    mini: false,
  },
  gradients: [
  ],
  images: [
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-2.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-3.jpg',
    // 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-4.jpg',
  ],
  notifications: [],
  rtl: false,
  mainTabs: allTabs
}

const mutations = make.mutations(state)

const actions = {
  fetchUser: ({ commit, dispatch }) => {
    fetchUserService()
      .then((response) => {
        const user = response.data
        user.permissions = {
          site: {
            add: user.is_staff,
            change: user.is_staff,
            delete: user.is_staff
          },
          event: {
            add: user.is_staff || user.is_dispatcher,
            change: user.is_staff || user.is_dispatcher,
            delete: user.is_staff
          },
          user: {
            read: user.is_dispatcher,
            add: user.is_staff || user.is_dispatcher,
            change: user.is_staff || user.is_dispatcher,
            delete: user.is_staff
          },
          task: {
            change: user.is_staff || user.is_dispatcher,
          }

        }
        console.log('=----------------user :>> ', user);
        commit('profile', user)

        commit('first_name', user.first_name)
        commit('last_name', user.last_name)
        commit('email', user.email)
        commit('fetched', true)
        commit('mainTabs', allTabs.filter(item => {
          if (item.access == null) { return true }
          return (item.access == 'admin' && user.is_staff) ||
            (item.access == 'dispatcher' && user.is_dispatcher)
        }))
        dispatch('update', user)
      })
  },
  clear: ({ commit }) => {
    // localStorage.removeItem('vuetify@user')

    commit('profile', null)
    commit('first_name', '')
    commit('last_name', '')
    commit('email', '')
    commit('fetched', false)
  },
  load: ({ commit }) => {
    const local = localStorage.getItem('vuetify@user') || '{}'
    const user = JSON.parse(local)
    user.gradients = [
      'rgb(36, 42, 56), rgb(36, 42, 56)'
    ]
    for (const key in user) {
      if (key == 'mainTabs') { continue }
      console.log("key", key, user[key])
      commit(key, user[key])
    }
    if (user.dark === undefined) {
      commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
    }
    commit('mainTabs', allTabs.filter(item => {
      if (item.access == null) { return true }
      return (item.access == 'admin' && user?.profile?.is_staff) ||
      (item.access == 'dispatcher' && user?.profile?.is_dispatcher)
    }))
    user.drawer.gradient = 0
  },
  update: ({ state }) => {
    if (!IN_BROWSER) return

    localStorage.setItem('vuetify@user', JSON.stringify(state))
  }
}

const getters = {
  dark: () => { //state, getters
    return true
    // return (
    //   state.dark ||
    //   getters.gradient.indexOf('255, 255, 255') === -1
    // )
  },
  gradient: state => {
    return state.gradients[state.drawer.gradient]
  },
  image: state => {
    return state.drawer.image === '' ? state.drawer.image : state.images[state.drawer.image]
  },
  shortDisplayName: state => {
    if (state.first_name.length == 0) {
      return state.email
    }
    return state.first_name
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
