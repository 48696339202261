import httpClient from './http.client'
import { midnight } from '@/filters/date' //firstDayMonth, lastDayMonth,
import { addDays, addYears } from 'date-fns'
import { LabelId } from "@/store/modules/task"
class TaskService {
  getOrder
  getDispatcherConditions() {
    return [
      { id: "open", name: "Otwarte", params: { state: "open", task_type: 1 } },
      { id: "closed", name: "Zakończone", params: { state: "closed", task_type: 1 } }
    ]
  }
  getSiteConditions() {
    return [
      { name: "Interwencje", params: { task_type: 2 } },
    ]
  }
  getPatrolConditions() {
    return [
      { id: "open", name: "Aktualne", params: { task_type: 2, state: "open" } },
      { id: "closed", name: "Zakończone", params: { task_type: 2, state: "closed" } },
      // { id: "paid", name: "Płatne", params: { task_type: 2, cost: "paid"} },
      { id: "all", name: "Wszystkie", params: { task_type: 2 } }
    ]
  }
  getTaskOrdering() {
    return [
      { name: "Data utworzenia", ordering: "-created_at" },
      { name: "Data modyfikacji", ordering: "-modified_at" },
    ]
  }
  getRangeConditions(from, to) {
    let conditions = {}
    if (from) {
      conditions.created_at__gte = `${from.toISOString()}`
    }
    if (to) {
      conditions.created_at__lt = `${to.toISOString()}`
    }
    return conditions
  }
  getTaskRanges() {
    return [
      {
        name: "Ostatni miesiąc", range: () => {
          return this.getRangeConditions(addDays(new Date(), -30))
        }
      },
      {
        name: "Dziś", range: () => {
          let from = midnight(new Date())
          console.log('from :>> ', from);
          return this.getRangeConditions(from, null)
        }
      },
      {
        name: "Wczoraj", range: () => {
          let from = midnight(addDays(new Date(), -1))
          let to = midnight(new Date())
          return this.getRangeConditions(from, to)
        }
      },
      {
        name: "Ostatni rok", range: () => {
          let from = addYears(new Date(), -1)
          return this.getRangeConditions(from, null)
        }
      },
      {
        name: "Poprzedni rok", range: () => {
          const year = new Date().getFullYear()
          let from = new Date(year, 0, 1)
          let to = new Date(year - 1, 0, 1)
          return this.getRangeConditions(from, to)
        }
      },
    ]
  }
  fetchAll() {
    return httpClient.get('v1/tasks')
  }
  create(eventId, task) {
    if (!task.details) {
      task.details = {}
    }
    task.details.status = { label_id: LabelId.NEW }
    return httpClient.post('v1/events/' + eventId + '/tasks', task)
  }
  fetch(eventId, query = null) {
    if (query != null) {
      return httpClient.get('v1/events/' + eventId + '/tasks?' + query)
    } else {
      return httpClient.get('v1/events/' + eventId + '/tasks')
    }
  }

  fetchTasks(params) {
    const query = new URLSearchParams(params).toString()
    return httpClient.get(`v1/tasks?${query}`).then(r => r.data)
  }
  fetchLastUpdated(modified_after) {
    return httpClient.get('v1/tasks?modified_after=' + modified_after + '&limit=10')
  }
  fetchSiteTasks(siteId, params) {
    const query = new URLSearchParams(params).toString()
    return httpClient.get(`v1/sites/${siteId}/tasks?${query}`).then(r => r.data)
  }
  get({siteId, taskId}) {
    return httpClient.get(`v1/sites/${siteId}/tasks/${taskId}`).then(r => r.data)
  }
  putMention(name, siteId, taskId, user, payload = {}) {
    if (user.id == 0) {
      payload = { ...payload, ...user }
    }
    return httpClient.put(`v1/sites/${siteId}/tasks/${taskId}/mention/${user.id}/${name}`, payload)
  }

  putNotifiedUser(siteId, taskId, user, channel = null) {
    return this.putMention('notified', siteId, taskId, user, { channel: channel })
  }
  putCanceledUser(siteId, taskId, user) {
    return this.putMention('canceled', siteId, taskId, user)
  }
  putFoundUser(siteId, taskId, user) {
    return this.putMention('found', siteId, taskId, user)
  }

  deleteMention(name, siteId, taskId, user) {
    return httpClient.delete(`v1/sites/${siteId}/tasks/${taskId}/mention/${user.id}/${name}`)
  }

  deleteNotifiedUser(siteId, taskId, user) {
    return this.deleteMention('notified', siteId, taskId, user)
  }
  deleteCanceledUser(siteId, taskId, user) {
    return this.deleteMention('canceled', siteId, taskId, user)
  }
  deleteFoundUser(siteId, taskId, user) {
    return this.deleteMention('found', siteId, taskId, user)
  }
  updateStats({siteId, taskId, stats}) {
    return httpClient.put(`v1/sites/${siteId}/tasks/${taskId}/stats`, stats)
  }
  updateTask(siteId, taskId, task) {
    return httpClient.put('v1/sites/' + siteId + '/tasks/' + taskId, task)
  }
  closeTask(siteId, taskId, message = null, comment = null) {
    const status = { label_id: LabelId.DONE, message: message, comment: comment }
    return this.setStatus(siteId, taskId, status)
  }
  allowToClosedTask(siteId, taskId) {
    var payload = { can_closed: true }
    return httpClient.put(`v1/sites/${siteId}/tasks/${taskId}/options/allow_to_closed`, payload)
  }
  restartTask({ siteId, taskId, message, comment }) {
    const status = { label_id: LabelId.NEW, message: message, comment: comment }
    return this.setStatus(siteId, taskId, status)
  }
  cancelTask(siteId, taskId, message = null, comment = null, user = null) {
    const status = { label_id: LabelId.CANCELED, message: message, comment: comment, user: user }
    return this.setStatus(siteId, taskId, status)
  }
  setCost(siteId, taskId, token) {
    return httpClient.post( 'v1/sites/' + siteId + '/tasks/' + taskId + '/cost', { token: token.value, name: token.short });
  }
  setStatus(siteId, taskId, status) {
    return httpClient.put(`v1/sites/${siteId}/tasks/${taskId}/status`, status)
  }
  addComment(siteId, taskId, comment, more) {
    return this.setStatus(siteId, taskId, { label_id: LabelId.COMMENT, message: comment, comment: more })
  }
  // updateComment(siteId, taskId, commentId, comment) {
  //   console.log('update comment :>> ', siteId, taskId, commentId, comment);
  // }
  // deleteComment(siteId, taskId, commentId) {
  //   console.log('delete comment :>> ', siteId, taskId, commentId);
  //   // return httpClient.delete(`v1/sites/${siteId}/tasks/${taskId}/comments/${commentId}`)
  // }
}

export default new TaskService()