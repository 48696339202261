import httpClient from './http.client'

export function refreshTokenApi(refreshToken) {
  return httpClient.post('v1/user/token/refresh', { refresh: `${refreshToken}` })
}

export function login(login, password) {
  return httpClient.post('v1/user/login', { username: login, password: password })
}

export function fetchUser() {
  return httpClient.get('v1/user')
}

export function changePassword(new_password1, new_password2, uid, token) {
  const payload = {
    "new_password1": new_password1,
    "new_password2": new_password2,
    "uid": uid,
    "token": token
  }
  if (uid && token) {
    return httpClient.post('v1/user/password/reset/confirm', payload).then(r => r.data)
  } else {
    return httpClient.post('v1/user/password/change', payload).then(r => r.data)
  }
}

export function resetPassword(email) {
  const payload = {
    "email": email
  }
  return httpClient.post('v1/user/password/reset', payload).then(r => r.data)
}

export function signUp(firstName, lastName, email, password) {
  const payload = {
    "first_name": firstName,
    "last_name": lastName,
    "username": email,
    "email": email,
    "password1": password,
    "password2": password
  }
  return httpClient.post('v1/user/signup', payload).then(r => r.data)
}

export function confirmEmail(token) {
  return httpClient.post('v1/user/confirm-email', { key: token }).then(r => r.data)
}

export function resendEmail(email) {
  return httpClient.post('v1/user/signup/email-verification-resend', { email: email }).then(r => r.data)
}

