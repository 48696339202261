<template>
  <dialog-btn v-bind="$attrs" v-on="$listeners">
    Anuluj
  </dialog-btn>
</template>

<script>
export default {
  name: "CancelBtn",
}
</script>
