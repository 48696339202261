<template>
  <icon-btn v-bind="$attrs" v-on="$listeners">
  </icon-btn>
</template>

<script>
export default {
  name: "ToolbarBtn",
  props: {
    text: {
      type: String,
      default: "OK",
    },
  },
}
</script>
