<template>
  <VueCtkDateTimePicker
    v-bind="$attrs"
    v-model="value"
    format="YYYY-MM-dd HH:mm"
    color="#f64e65"
  >
  </VueCtkDateTimePicker>
</template>

<script>
export default {
  name: "DataTimePicker",
  props: {
    value: {
      type: Date,
      default: () => new Date()
    },
  //   label: {
  //     type: String,
  //     default: () => "Data"
  //   },
  //   nullable: {
  //     type: Boolean
  //   },
  //   disabled: {
  //     type: Boolean
  //   }
  },
  beforeMount() {
    console.log("this.value :>> ", this.value)
    if (this.value && !isNaN(this.value)) {
      this.empty = false
      this.date = this.value.toISOString().substr(0, 10)
      this.time = this.value.toLocaleTimeString().substr(0, 5)
    } else {
      this.empty = true
      this.date = new Date().toISOString().substr(0, 10)
      this.time = new Date().toLocaleTimeString().substr(0, 5)
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    time: new Date().toLocaleTimeString().substr(0, 5),
    menuDate: null,
    empty: false,
    yourValue: null
  }),
  watch: {
    empty(value) {
      if (value) {
        value = null
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    },
    value(value) {
      console.log("this.valuexxxxx :>> ", this.value)
      if (value && !isNaN(value)) {
        this.empty = false
        this.date = value.toISOString().substr(0, 10)
        this.time = value.toLocaleTimeString().substr(0, 5)
      } else {
        this.empty = true
        this.date = new Date().toISOString().substr(0, 10)
        this.time = new Date().toLocaleTimeString().substr(0, 5)
      }
    },
    time() {
      if (this.empty) {
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    },
    date: function() {
      if (this.empty) {
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    }
  },
  methods: {
    resetTime() {
      this.time = new Date().toLocaleTimeString().substr(0, 5)
      this.date = new Date().toISOString().substr(0, 10)
    },
    clearTime() {
      // this.time = null
      // this.date = null
    }
  }
}
</script>