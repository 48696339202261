<template>
  <validation-provider v-slot="{ errors }" rules="" name="user.combo">
    <v-autocomplete
      ref="user"
      dense
      v-model="selected"
      v-bind="$attrs"
      :items="items"
      item-value="id"
      :loading="isLoading"
      :label="label"
      :hint="hint"
      :search-input.sync="search"
      outlined
      :multiple="multiple"
      no-filter
      auto-select-first
      :error-messages="errors"
      @keyup.delete.exact.prevent="onClear"
      return-object
      hide-details="auto"
    >
      <template slot="selection" slot-scope="{ item }">
        <v-chip small v-if="multiple" close @click:close="remove(item)">
          {{ item | userName }}</v-chip
        >
        <div v-if="!multiple">{{ item | userName }}</div>
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-row class="ma-2" align="center">
          <user-avatar :user="item"></user-avatar>

          <div>
            {{ item | userName }}
            <v-chip
              small
              v-for="(tag, index) in item.tags"
              :key="index"
              :color="tag.color"
              class="mx-2"
              >{{ tag.title }}</v-chip
            >
          </div>
          <div v-if="workInfo(item)">
            <v-icon small class="ml-4 mr-1">
              mdi-briefcase-outline
            </v-icon>
            {{ workInfo(item) }}
          </div>
        </v-row>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
export default {
  name: "UsersSelect2",

  beforeMount() {
    this.isLoading = true
    this.users().then(data => {
      this.items = data
      this.isLoading = false
      for (var user in this.usersSelected) {
        this.items.push(user)
      }
      this.selected = this.usersSelected
    })
  },
  data: () => ({
    selected: null,
    isLoading: false,
    // internalSearch: null,
    search: null,
    items: [],
  }),
  props: {
    label: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    autocommit: {
      type: Boolean,
      default: false,
    },
    searchInput: {
      default: undefined,
    },
    users: {
      type: Function,
      default: () => {
        return new Promise((resolve) => {
          resolve([]);
        });
      },
    },
    usersSelected: {
      type: Object,
      default: null,
    },
  },
  watch: {
    search(value) {
      this.isLoading = true;
      // console.log("searching :>> ", this.users, this.users(value));
      this.users(value)
        .then((users) => {
          this.items = users;
          if (this.selected) {
            this.items.push(this.selected)
          }
          this.isLoading = false;
        })
        .finally(() => (this.isLoading = false));
      this.isLoading = false;
    },
    users(value) {
      this.isLoading = true;
      value.then((data) => {
        this.items = data;
        this.isLoading = false;
      });
    },
    usersSelected(value) {
      this.selected = value;
    },
    selected(value) {
      this.$emit("user-selected", value);
    },
  },
  methods: {
    workInfo(user) {
      if (user?.job_title && user?.organization) {
        return this.$t("user.work.information", {
          job_title: user.job_title,
          organization: user.organization,
        });
      } else if (user?.job_title) {
        return user.job_title;
      } else if (user?.organization) {
        return user.organization;
      } else {
        return "";
      }
    },
    remove(item) {
      const index = this.selected.findIndex(obj => obj.id == item.id);
      if (index >= 0) this.selected.splice(index, 1);
    },
    onClear() {
      if (!this.search) {
        this.selected = null
      }
    }
  },
};
</script>
