<template>
  <!-- <v-card outlined flat style="background-color: transparent;"> -->
  <v-row dense align="center">
    <v-col cols="12" md="2" v-if="nullable">
      <v-checkbox v-model="empty" label="Brak"></v-checkbox>
    </v-col>
    <v-col cols="12" xs="6" sm="4" md="3" lg="2" xl="1" v-if="!empty">
      <v-menu
        ref="menuDate"
        v-model="menuDate"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            :disabled="disabled"
            v-bind="attrs"
            hide-details="true"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          @click="$refs.menuDate.save(date)"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuDate = false">
            {{ $t("event.picker.cancel") }}
          </v-btn>
          <v-btn text color="primary" @click="$refs.menuDate.save(date)">
            {{ $t("event.picker.ok") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" xs="6" sm="2" md="2" lg="1" xl="1" v-if="!empty">
      <v-text-field
        :label="$t('event.time')"
        v-model="time"
        :disabled="disabled"
        type="time"
        hide-details="true"
      ></v-text-field>
    </v-col>
    <v-col class="ml-5" v-if="!empty">
      <v-btn v-if="!disabled" @click="resetTime">
        {{ $t("event.now") }}
      </v-btn>
      <!-- <v-btn v-if="!disabled && nullable" text @click="clearTime"> Usuń </v-btn> -->
    </v-col>
  </v-row>
  <!-- </v-card> -->
</template>

<script>
export default {
  name: "DataTimeEditor",
  props: {
    value: {
      type: Date,
      default: () => new Date()
    },
    label: {
      type: String,
      default: () => "Data"
    },
    nullable: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  beforeMount() {
    console.log("this.value :>> ", this.value)
    if (this.value && !isNaN(this.value)) {
      this.empty = false
      this.date = this.value.toISOString().substr(0, 10)
      this.time = this.value.toLocaleTimeString().substr(0, 5)
    } else {
      this.empty = true
      this.date = new Date().toISOString().substr(0, 10)
      this.time = new Date().toLocaleTimeString().substr(0, 5)
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    time: new Date().toLocaleTimeString().substr(0, 5),
    menuDate: null,
    empty: false,
    yourValue: null,
    shortcut: ""
  }),
  watch: {
    empty(value) {
      if (value) {
        value = null
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    },
    value(value) {
      console.log("this.valuexxxxx :>> ", this.value)
      if (value && !isNaN(value)) {
        this.empty = false
        this.date = value.toISOString().substr(0, 10)
        this.time = value.toLocaleTimeString().substr(0, 5)
      } else {
        this.empty = true
        this.date = new Date().toISOString().substr(0, 10)
        this.time = new Date().toLocaleTimeString().substr(0, 5)
      }
    },
    time() {
      if (this.empty) {
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    },
    date: function() {
      if (this.empty) {
        this.$emit("input", null)
      } else {
        this.$emit("input", new Date(this.date + "T" + this.time))
      }
    }
  },
  methods: {
    resetTime() {
      this.time = new Date().toLocaleTimeString().substr(0, 5)
      this.date = new Date().toISOString().substr(0, 10)
    },
    clearTime() {
      // this.time = null
      // this.date = null
    }
  }
}
</script>