import { make } from 'vuex-pathify'
import router from '@/router'
import { login, refreshTokenApi } from '@/services/auth.service'
import { isValidToken, getTimeDiff, decodeJWT } from '@/util/jwt-util'

const state = {
  authorized: false,
  jwt: null,
}

const mutations = make.mutations(state)

const getters = {
  isAuthorized: state => state.authorized,
  expire: state => state.jwt && state.jwt.exp,
  accessToken: state => state.jwt && state.jwt.access,
}

function clearTokens(commit) {
  commit('authorized', false)
  commit('jwt', null)
  localStorage.removeItem('jwt')
}

function loadTokens(state) {
  state.jwt = JSON.parse(localStorage.getItem('jwt'))
}

function updateTokens(state, tokens) {
  const decodeAccessToken = decodeJWT(tokens && tokens.access)
  state.jwt = {
    access:  tokens && (tokens.access || ''),
    refresh: tokens && (tokens.refresh || ''),
    sub: decodeAccessToken && (decodeAccessToken.sub || ''),
    rol: decodeAccessToken && (decodeAccessToken.rol || ''),
    iat: decodeAccessToken && (decodeAccessToken.iat || ''),
    exp: decodeAccessToken && (decodeAccessToken.exp || '')
  }
  localStorage.setItem('jwt', JSON.stringify(state.jwt))
}

function authorize(state, commit, dispatch, tokens) {
  console.log('autorize :>> ', tokens);
  if (!tokens) {
    return
  }
  if (isValidToken(tokens && tokens.access)) {
    updateTokens(state, tokens)
    commit('authorized', true)
    dispatch('refreshToken')
  } else if(isValidToken(tokens && tokens.refresh)) {
    dispatch('refreshToken')
  } else {
    console.log("not ok")
    dispatch('logout')
  }
}

function fetchNewAccessToken(state, commit, dispatch ) {
  console.log('state.jwt.refresh :>> ', state.jwt.refresh)
  refreshTokenApi(state.jwt && state.jwt.refresh)
    .then(async response => {
      const data = {
        access: response.data.access,
        refresh: state.jwt.refresh
      }
      if (response.data.refresh) {
        data.refresh = response.data.refresh
      }
      console.log('state.jwt :<< ', data)
      authorize(state, commit, dispatch, data)
    })
}

const actions = {
  init: async ({ state, commit, dispatch }) => {
    loadTokens(state)
    authorize(state, commit, dispatch, state.jwt)
  },

  refreshToken: ({ state, commit, dispatch }) => {
    const renewalTimeBuffer = 5000
    const timeDiff = getTimeDiff(state.jwt && state.jwt.exp)
    const timeoutCount = renewalTimeBuffer < timeDiff ? timeDiff - renewalTimeBuffer : timeDiff
    console.log("refresh token after", timeoutCount, "ms")
    if (timeoutCount) {
      setTimeout(() => {
        fetchNewAccessToken(state, commit, dispatch)
      }, timeoutCount)
    } else {
      fetchNewAccessToken(state, commit, dispatch)
    }
  },

  authenticationUser: ({ state, commit, dispatch }, { email, password }) => {
    return login(email, password)
      .then(response => {
        authorize(state, commit, dispatch, response.data)
        dispatch('user/fetchUser', null, { root: true })
      })
  },

  logout: async ({ commit, dispatch }) => {
    clearTokens(commit)
    dispatch('user/clear', null, {root: true})
    if (router) { //init is before router
      router.push({ name: 'SignIn' }).catch(() => {});
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
