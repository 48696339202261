<template>
  <v-btn color="error" v-bind="$attrs" v-on="$listeners">
    {{  text }}
  </v-btn>
</template>

<script>
export default {
  name: "DestructiveBtn",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
}
</script>
