<template>
  <v-tabs class="v-tabs--monpatrol" v-bind="$attrs" v-on="$listeners" @change="handleChange">
    <slot />
    <slot name="items" />
  </v-tabs>
</template>

<script>
export default {
  name: 'AppTabs',

  inject: ['theme'],

  props: {
  },
  methods: {
    handleChange(value) {
      console.log('handle change :>> ', value);
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="sass">
  .v-tabs--pill
    .v-tab,
    .v-tab:before
      border-radius: 24px

    &.v-tabs--icons-and-text
      &:not(.v-tabs--vertical) > .v-tabs-bar
        height: 100px
      .v-tab,
      .v-tab:before
        border-radius: 4px

  .v-tabs--monpatrol
    .v-tab
      text-transform: none
    .v-tabs-bar
       background-color: transparent !important
</style>
