<template>
  <v-row>
      todo
  </v-row>
</template>

<script>
export default {
  name: "NoteEditor",
  beforeMount() {},
  props: {},
  data: () => ({}),
  watch: {},
  filters: {},
  computed: {},
  methods: {},
};
</script>