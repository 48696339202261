<template>
  <dialog-card :title="title" :actions="actions" :handler="handlerClick" titleClass="pb-0" ref="card">
    <v-card-text class="pt-0 px-0">
        {{ subtitle }}
    </v-card-text>
    <v-card-text class="py-0">
      <users-combo ref="user" autofocus label="Użytkownik" :outlined="false" :dense="false" :users="users"
        @user-selected="usersChanged" @keyup.alt.enter.native="onEnter" :multiple="false"></users-combo>
    </v-card-text>
    <user-info :routerLink="false" :user="selected"></user-info>
  </dialog-card>
</template>

<script>
import _ from 'lodash'

export default {
  name: "NotifyDialog",
  data: () => ({
    actions: {
      false: "Anuluj",
      true: {
        color: "success",
        text: "OK"
      },
    },
    selected: null
  }),
  mounted() {
    this.selected = this.usersSelected
  },
  watch: {
    selected(value) {
      console.log("new selected", value)
    }
  },
  computed: {},
  props: {
    title: {
      type: String,
      default: "Powiadom użytkownika"
    },
    subtitle: {
      type: String,
      default: null
    },
    userSelected: {
      type: Array,
      default: () => null
    },
    users: {
      type: Promise,
      default: () => new Promise(resolve => resolve([]))
    }
  },
  methods: {
    usersChanged(value) {
      if (_.isString(value)) {
        value = { id: 0, full_name: value }
      }
      this.selected = value
    },
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.selected != null
      if (!valid) {
        this.$refs.user.focus()
        return false
      }
      this.$emit('submit', action.key ? this.selected : action.key)
    }
  }
}
</script>
