<template>
  <v-tooltip bottom v-if="visible">
    <template v-slot:activator="{ on }">
      <v-chip class="mr-2 mt-1" v-bind="$attrs" v-on="on" @click="onClick" @click:close="onClickClose" :small="small">

        <v-icon left :small="small">
            {{ icon }}
        </v-icon>
        <slot />
        {{ text }}
      </v-chip>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipChip',

  props: {
    visible: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    },
    onClickClose() {
      this.$emit('click:close')
    }
  }
}
</script>
