export const NoteType = {
  ALARM: 1,
  TROUBLE: 2,
  OPEN_CLOSE: 3,
  SERVICE: 4,
  GENERAL: 10,
};

import { parseJSON } from 'date-fns';

export class Note {
  static get(id) {
    return this.all().find((item) => item.id == id);
  }

  static allTypes() {
    return NoteType;
  }
  static all() {
    return [
      {
        id: NoteType.SERVICE,
        name: 'Prace serwisowe',
        image: 'mdi-wrench',
        color: 'green',
        textColor: 'white',
        rangeLabel: 'Data serwisu',
        startLabel: 'Początek',
        endLabel: 'Zakończenie',
        code: '617.1',
        duration: 4,
      },
      {
        id: NoteType.ALARM,
        name: 'Pomijaj alarm',
        image: 'mdi-bell',
        color: 'red',
        textColor: 'white',
        rangeLabel: 'Okres',
        startLabel: 'Od',
        endLabel: 'Do',
        code: '140.1',
        duration: 24,
      },
      {
        id: NoteType.TROUBLE,
        name: 'Pomijaj awarie',
        image: 'mdi-alert',
        color: 'orange',
        textColor: 'white',
        rangeLabel: 'Okres',
        startLabel: 'Od',
        endLabel: 'Do',
        code: '399.1',
        duration: 24,
      },
      {
        id: NoteType.OPEN_CLOSE,
        name: 'Ignoruj stan',
        image: 'mdi-eye',
        color: 'light-green',
        textColor: 'white',
        rangeLabel: 'Okres',
        startLabel: 'Od',
        endLabel: 'Do',
        code: '932.1',
        duration: 24 * 7,
      },
      {
        id: NoteType.GENERAL,
        name: 'Inne',
        image: 'mdi-information',
        color: 'blue',
        textColor: 'white',
        rangeLabel: 'Okres',
        startLabel: 'Od',
        endLabel: 'Do',
        code: '931.1',
        duration: null,
      },
    ];
  }

  static isPast(note) {
    if (note?.expires_at == null) {
      return false;
    }
    return parseJSON(note?.expires_at) < new Date();
  }

  static isFuture(note) {
    return parseJSON(note?.beginning_at) > new Date();
  }
}
