import Vue from 'vue'
import {
  formatDistance,
  parseJSON,
  differenceInDays,
  differenceInHours,
  isSameDay,
  formatRelative,
  format,
} from 'date-fns'

import en from 'date-fns/locale/en-US'
import pl from 'date-fns/locale/pl'

export function defaultLocale() {
  switch (window.navigator.language) {
    case 'pl-PL':
      return pl
    default:
      return en
  }
}

export function midnight(date) {
  date.setHours(0, 0, 0, 0)
  return date
}

export function firstDayMonth(month) {
  return new Date(new Date().getFullYear(), month, 1)
}

export function lastDayMonth(month) {
  return new Date(new Date().getFullYear(), month + 1, 0)
}

Vue.filter('dateFull', function (value) {
  return parseJSON(value).toLocaleString(defaultLocale(), {
    dateStyle: 'full',
  })
})
Vue.filter('smartTimeFormat', function(value) {
  const date = parseJSON(value)
  const now = new Date()
  // const diffTime = Math.abs(date2 - date1);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // if (isSameDay(date, now)) {
  //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // } else
  if (isSameDay(now, date) == 1) {
    return date.toLocaleString(defaultLocale(), {
      timeStyle: 'short'
    })
  } else if (differenceInDays(now, date) < 7) {
    return formatRelative(date, now, { locale: defaultLocale() })
    // return date.toLocaleString([], { hour: '2-digit', minute: '2-digit',  weekday:'long', locale: defaultLocale() })
  } else {
    return date.toLocaleString(defaultLocale(), {
      timeStyle: 'short',
      dateStyle: 'medium',
    })
  }
})
Vue.filter('dateTimeMedium', function(value) {
  if (!value) return ''
  return parseJSON(value).toLocaleString(defaultLocale(), {
    timeStyle: 'medium',
    dateStyle: 'medium',
  })
})

Vue.filter('localDateTime', function (value) {
  if (!value) return ''
  return format(parseJSON(value), 'yyyy-MM-dd HH:mm:ss', { locale: defaultLocale() })
  // .toLocaleString(defaultLocale(), {
  //   timeStyle: 'full',
  //   dateStyle: 'medium',
  // })
})

Vue.filter('dateMediumTimeShort', function(value) {
  if (!value) return ''
  return parseJSON(value).toLocaleString(defaultLocale(), {
    timeStyle: 'short',
    dateStyle: 'medium',
  })
})

Vue.filter('timeShort', function (value) {
  if (!value) return ''
  return parseJSON(value).toLocaleString(defaultLocale(), {
    timeStyle: 'short',
  })
})

Vue.filter('timeMedium', function (value) {
  if (!value) return ''
  return parseJSON(value).toLocaleString(defaultLocale(), {
    timeStyle: 'medium',
  })
})

Vue.filter('dateMedium', function(value) {
  if (!value) return ''
  return parseJSON(value).toLocaleString(defaultLocale(), {
    dateStyle: 'medium',
  })
})

Vue.filter('formatDistanceWithPrefix', function(value) {
  if (!value) return ''
  const date = parseJSON(value)
  return formatDistance(date, new Date(), {
    addSuffix: true,
    locale: defaultLocale(),
  })
})

Vue.filter('timeDuration', function(seconds) {
  if (!seconds) return ''
  const date = new Date(new Date().getTime() + seconds * 1000)
  return formatDistance(date, new Date(), {
    addSuffix: false,
    locale: defaultLocale(),
  })
})

Vue.filter('timeDurationWithSuffix', function (seconds) {
  if (!seconds) return ''
  const date = new Date(new Date().getTime() + seconds * 1000)
  return formatDistance(date, new Date(), {
    addSuffix: true,
    locale: defaultLocale(),
  })
})

Vue.filter('formatDistance', function(value) {
  if (!value) return ''
  const date = parseJSON(value)
  return formatDistance(date, new Date(), {
    addSuffix: false,
    locale: defaultLocale(),
  })
})

Vue.filter('distanceInHours', function(value) {
  const date = parseJSON(value)
  const now = new Date()
  return differenceInHours(now, date)
})

Vue.filter('isSameDay', function(value) {
  const date = parseJSON(value)
  const now = new Date()
  return isSameDay(now, date) == 1
})

Vue.filter('backendISO', function(value) {
  return value.toISOString().replace('Z', '000')
});

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
  },
  enumerable: false,
})
