<template>
  <v-tooltip bottom v-if="status" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        outlined
        label
        :color="color"
        v-bind="attrs"
        v-on="on"
        class="ml-0 mb-0"
        :text-color="color"
        :large="large"
        :small="small"
      >
        <v-icon> {{ icon }} </v-icon>
      </v-chip>
    </template>
    <div class="text-overline"> {{ message }} </div>
    <v-divider class="white"></v-divider>
    <div v-for="(line, i) in details" :key="i"> {{ line }} </div>
  </v-tooltip>
</template>

<script>
import { Labels } from "@/store/modules/task"

export default {
  name: "TaskStatusIcon",

  beforeMount() {},
  data: () => ({}),
  props: {
    status: {
      type: Object,
      default: null
    },
    stats: {
      type: Object,
      default: null
    },
    large: Boolean,
    small: Boolean,
    disabled: Boolean
  },
  computed: {
    isProblems() {
      return this.status.problems
    },
    color() {
      return Labels.get(this.status.label_id).color
    },
    details() {
      var messages = []
      // messages.push(this.title)
      // if (this.isProblems) {
      //   messages.push(this.status.problems)
      // }
      const read_at = this.stats?.read_at
      if (read_at) {
        messages.push("Odczytany: " + this.$options.filters.dateMediumTimeShort(read_at))
      }
      const travel_started_at = this.stats?.travel_started_at
      if (travel_started_at) {
        messages.push("Wyruszył w drogą: " + this.$options.filters.dateMediumTimeShort(travel_started_at))
      }
      const travel_finished_at = this.stats?.travel_finished_at
      if (travel_finished_at) {
        messages.push("Na miejscu: " + this.$options.filters.dateMediumTimeShort(travel_finished_at))
      }
      const travelTime = this.stats?.travel_time
      if (travelTime) {
        messages.push(`${this.$t("task.stats.travel.time")}: ${this.$options.filters.timeDuration(travelTime)}`)
      }
      return messages
    },
    message() {
      if (this.isProblems) {
        return this.status.problems
      }
      return this.title
    },
    title() {
      return Labels.get(this.status.label_id).title
    },
    icon() {
      if (this.status.paused) {
        return "mdi-pause"
      }
      if (this.isProblems) {
        return "mdi-alert-decagram-outline"
      }
      return Labels.get(this.status.label_id).icon
    }
  },
  methods: {},
  watch: {}
}
</script>
