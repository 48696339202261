<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    dense
    return-object
    hide-details
    label="Zakres"
  >
  </v-select>
</template>

<script>
export default { name: "AppDateRangePicker" }
</script>
