<template>
  <div>
    <v-btn :color="color" v-bind="$attrs" v-on="$listeners">
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'AppBtn',

  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
