var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.createdAt)?_c('div',_vm._g(_vm._b({staticClass:"details--text ml-1 mr-3"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"details"}},[_vm._v("mdi-send-outline")]),_vm._v(" "+_vm._s(_vm._f("smartTimeFormat")(_vm.createdAt))+" ")],1):_vm._e()]}}])},[_c('div',[_vm._v("Data rozpoczęcia")]),(_vm.createdAt)?_c('div',[_vm._v(_vm._s(_vm._f("dateMediumTimeShort")(_vm.createdAt)))]):_vm._e()]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"details--text ml-1 mr-3"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"details"}},[_vm._v("mdi-timer-outline")]),_vm._v(_vm._s(_vm._f("timeDuration")(_vm.duration(_vm.task)))+" ")],1)]}}])},[_c('div',[_vm._v("Czas trwania")]),_c('div',[_vm._v(_vm._s(_vm._f("timeDuration")(_vm.duration(_vm.task))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"details--text ml-1 mr-3"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"details"}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm._f("formatDistanceWithPrefix")(_vm.date))+" ")],1)]}}])},[_c('div',[_vm._v(_vm._s(_vm.label))]),_c('div',[_vm._v(" "+_vm._s(_vm._f("dateMediumTimeShort")(_vm.date))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }