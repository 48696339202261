<template>
  <v-container>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="details--text ml-1 mr-3" v-if="createdAt">
          <v-icon small class="mr-1" color="details">mdi-send-outline</v-icon>
          {{ createdAt | smartTimeFormat }}
        </div>
      </template>
      <div>Data rozpoczęcia</div>
      <div v-if="createdAt">{{ createdAt | dateMediumTimeShort }}</div>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="details--text ml-1 mr-3">
          <v-icon small class="mr-1" color="details">mdi-timer-outline</v-icon
          >{{ duration(task) | timeDuration }}
        </div>
      </template>
      <div>Czas trwania</div>
      <div>{{ duration(task) | timeDuration }}</div>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="details--text ml-1 mr-3">
          <v-icon small class="mr-1" color="details">{{ icon }}</v-icon>
          {{ date | formatDistanceWithPrefix }}
        </div>
      </template>
      <div>{{ label }}</div>
      <div>
        {{ date | dateMediumTimeShort }}
      </div>
    </v-tooltip>
  </v-container>
</template>

<script>
import UserTask from "@/store/modules/task"

export default {
  name: "TaskTimes",
  data() {
    return {}
  },
  props: {
    task: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    createdAt() {
      return this.task?.created_at
    },
    date() {
      if (this.task?.closed_at) {
        return this.task?.closed_at
      } else {
        return this.task?.modified_at
      }
    },
    label() {
      if (this.task?.closed_at) {
        return "Data zakończenia"
      } else {
        return "Data ostatniej zmiany"
      }
    },
    icon() {
      if (this.task?.closed_at) {
        return "mdi-stop-circle-outline"
      } else {
        return "mdi-update"
      }
    }
  },
  methods: {
    duration(task) {
      return UserTask.duration(task)
    }
  }
}
</script>