<template>
  <dialog-card title="Drukowanie zdarzeń" :actions="actions" :handler="handlerClick" ref="card">
    <v-card-text class="py-0">
      <v-text-field autofocus class="mt-2" label="Nagłówek" v-model="result.title" @keyup.enter.stop="onEnter">
      </v-text-field>
      <v-select class="mt-2" disabled label="Format" v-model="result.type" :items="format" />
      <v-select class="mt-2" label="Szczegóły" v-model="result.details" :items="details"/>
    </v-card-text>

  </dialog-card>
</template>

<script>

export default {
  name: "DownloadEventsDialog",
  data: () => ({
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
    format: [
      { text: 'pdf', value: 'pdf' },
      { text: 'json', value: 'json' }
    ],
    details: [
      { text: 'Brak', value: 'none' },
      { text: 'Minimalne', value: 'minimal' },
      { text: 'Podstawowe', value: 'regular' },
      { text: 'Pełne', value: 'full' },
    ],
    result: {
      title: "",
      type: "pdf",
      details: "regular",

    }
  }),
  mounted() {
    this.result.title = this.title
  },
  watch: {
  },
  computed: {
  },
  props: {
    title: {
      type: String,
      default: () => null
    },
  },
  methods: {
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      this.$emit('submit', action.key ? this.result : action.key)
    }
  },
};
</script>
