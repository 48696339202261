<template>
  <material-card flat outlined class="flex-grow-1" color="white">
    <template #heading>
      <task-status-chip large :status="status"></task-status-chip>
    </template>

    <template #title>
      <!-- <div>{{ event.code | codeAsString }}</div> -->
      <!-- <div class="text-subtitle-2">{{ eventInfo }}</div> -->
      <!-- <site-link :site="event.site"></site-link> -->
      <!-- <div class="note text-subtitle-2">{{ dispatcherTaskMessage }}</div> -->
      <!-- <div>{{ title }}</div> -->

      <v-hover v-slot:default="{ hover }">
        <v-row dense align="center">
          <div class="text--primary my-2">
            {{ task.description }}
          </div>
          <div class="text--disabled text-body-1 my-2" v-if="!task.description">{{ $t('patrolTask.description') }}</div>
          <icon-btn v-if="hover && !readOnly" small tooltip="Edytuj szczegóły" icon="mdi-pencil"
            @click="updateDescription" />
        </v-row>
      </v-hover>
    </template>

    <v-card-text class="py-0">
      <v-row align="center">
        <v-col class="ma-0" cols="12">
          <task-timeline :task="task" :site="site" :readOnly="readOnly" />
          <v-textarea dense v-model='comment' append-outer-icon='mdi-send' @click:append-outer="addComment" clearable
                autofocus filled type="text" @click:clear="comment = null" v-if="!isClose" outlined label="Komentarz"
                @keydown.enter.exact.prevent="addComment" @keydown.enter.shift.exact.prevent="addLineToComment" rows="1"
                auto-grow hide-details="auto" />
        </v-col>
      </v-row>
    </v-card-text>

    <template v-slot:actions v-if="!readOnly">
      <div></div>
      <!-- <icon-btn v-if="canEdit" tooltip="Edytuj" icon="mdi-pencil" @click="onEditStats" /> -->
      <icon-btn v-if="canCancel" tooltip="Odwołaj" icon="mdi-car-off" @click="cancelTask"></icon-btn>
      <icon-btn v-if="!readOnly" tooltip="Opłata" icon="mdi-currency-usd" @click="setPayment"></icon-btn>
      <icon-btn :visible="!readOnly" tooltip="Osoba w obiekcie" icon="mdi-account-check-outline" @click="foundUsers" />
      <icon-btn tooltip="Skład" icon="mdi-account-group-outline" @click="squadInfo" />
      <!-- <icon-btn v-if="debug && !readOnly" tooltip="Rozpocznij od nowa" icon="mdi-restore" @click="restartTask"></icon-btn> -->
      <v-spacer />
      <!-- <icon-btn v-if="isStepOnTheWay && !readOnly" tooltip="W drodze" icon="mdi-map-marker" @click="onTheWay"></icon-btn>
      <icon-btn v-if="isStepOnPlace && !readOnly" tooltip="Na miejscu" icon="mdi-history" @click="onAtLocation"></icon-btn>
      <icon-btn v-if="isStepToClose && debug && !readOnly" tooltip="Zakończ" icon="mdi-close" @click="onClose"></icon-btn> -->
      <v-btn v-if="nextStepButton" text @click="nextStepButton.action">
        <v-icon left>{{ nextStepButton.icon }}</v-icon>
        {{ nextStepButton.text }}
      </v-btn>
    </template>
  </material-card>
</template>

<script>
// import EventService from '@/services/event.service'
import { Labels, LabelId, setPaymentDialog, patrolSummaryDialog, foundUsersDialog, firstAssigneeUser } from "@/store/modules/task"
import TaskService from "@/services/task.service"
import SiteService from "@/services/site.service"
import UserService from "@/services/user.service"
import paidCard from "@/components/PaidCard.vue"
import patrolWorkingTimeCard from "./PatrolWorkingTimeCard.vue"
import patrolSummaryCard from "@/components/PatrolSummaryCard.vue"
import notifyDialog from "@/components/NotifyDialog.vue"
import payments from "@/store/modules/payments"
import TaskStatusChip from "./TaskStatusChip.vue"
import { sync } from 'vuex-pathify'
import { parseJSON } from "date-fns"

export default {
  components: { TaskStatusChip },
  name: "PatrolCard",

  beforeMount() {
    console.log('beforeMount :>> ', this.task);
    this.refreshNextButton()
  },
  data() {
    return {
      comment: null,
      value: null,
      nextStepButton: null
    }
  },
  props: {
    site: {
      type: null,
      default: null
    },
    task: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    task() {
      this.refreshNextButton()
    }
  },
  computed: {
    user: sync('user.profile'),
    debug() {
      const host = window.location.hostname
      return host == 'localhost' || host == 'test.monpatrol.pl'
    },
    travelStartedAt() {
      return this.task?.details.stats.travel_started_at
    },
    travelTime() {
      return this.task?.details?.stats?.travel_time
    },
    isStepOnTheWay() {
      return this.task?.details?.status?.label_id < LabelId.ON_THE_WAY
    },
    isStepOnPlace() {
      return this.task?.details?.status?.label_id == LabelId.ON_THE_WAY
    },
    isClose() {
      return this.task?.closed_at != null
    },
    isStepToClose() {
      return this.task?.details?.status?.label_id == LabelId.AT_LOCATION && !this.isClose
    },
    canEdit() {
      return this.user.permissions.task.change && this.travelTime != null
    },
    canCancel() {
      if (!this.isClose) return true
      if (this.task.details.status == null) return false
      const value = this.task.details.status.progress
      return value > 0 && value < 100
    },
    canRestart() {
      if (this.isClose) return false
      if (this.task.details.status == null) return false
      return this.task.details.status.progress > 0
    },
    status() {
      return this.task?.details?.status
    },
    statusMessage() {
      if (!this.status) {
        return null
      }
      let message = this.status.message
      if (this.status.comment) {
        message += ` - ${this.status.comment}`
      }
      return message
    },
    statusColor() {
      if (!this.status) {
        return Labels.get(0).color
      }
      return Labels.get(this.status.label_id).color
    },
    statusTitle() {
      if (!this.status) {
        return Labels.get(0).title
      }
      return Labels.get(this.status.label_id).title
    },
    statusIcon() {
      if (!this.status) {
        return Labels.get(0).icon
      }
      return Labels.get(this.status.label_id).icon
    },
    statusProgress() {
      if (!this.status) {
        return 0
      }
      return this.status.progress
    },
    payment() {
      return payments.findWithValue(this.task.details.cost?.token ?? -1)
    }
  },
  methods: {
    refreshNextButton() {
      const labelId = this.task?.details?.status?.label_id
      var nextLabel = null
      var nextAction = null
      if (labelId < LabelId.ON_THE_WAY) {
        nextLabel = Labels.get(LabelId.ON_THE_WAY)
        nextAction = this.onTheWay
      } else if (labelId == LabelId.ON_THE_WAY) {
        nextLabel = Labels.get(LabelId.AT_LOCATION)
        nextAction = this.onAtLocation
      } else if (labelId == LabelId.AT_LOCATION) {
        nextLabel = Labels.get(LabelId.DONE)
        nextAction = this.onClose
      }
      if (!nextAction) {
        this.nextStepButton = null
        return
      }
      this.nextStepButton = {
        icon: nextLabel.icon,
        text: nextLabel.title,
        action: nextAction
      }
    },
    onEditStats() {
      this.$router.push({
        name: "Edit Task Stats",
        params: {
          siteId: this.site.id,
          eventId: this.task.event,
          taskId: this.task.id,
          task: this.task
        }
      })
    },
    onTheWay() {
      console.log('onTheWay :>> ');
      const status = { label_id: LabelId.ON_THE_WAY, progress: 50 }
      TaskService.setStatus(this.site.id, this.task.id, status).then(res =>
        this.$emit("update:task", res.data)
      )
    },
    onAtLocation() {
      const status = { label_id: LabelId.AT_LOCATION, progress: 60 }
      TaskService.setStatus(this.site.id, this.task.id, status).then(res =>
        this.$emit("update:task", res.data)
      )
    },
    addComment() {
      if (!this.comment) {
        return
      }
      if (this.comment.trim().length == 0) {
        return
      }
      var lines = this.comment.split('\n');
      var comment = this.comment
      var more = null
      if (lines.length > 1) {
        comment = lines[0]
        lines.shift()
        more = lines.join('\n')
      }
      TaskService.addComment(this.site.id, this.task.id, comment, more).then(res => {
         this.$emit("update:task", res?.data)
        this.comment = null
      })

      // this.dispatcherTask.details.history.push({
      //   created_at: new Date().toISOString(),
      //   label_id: 300,
      //   comment: this.comment
      // })
      // this.comment = null
    },
    async foundUsers() {
      console.log('foundUsers :>> ', this.task.event);
      const args = {
        context: this,
        foundDialog: notifyDialog,
        taskService: TaskService,
        siteService: SiteService,
        site: this.site,
        task: this.task,
      }
      await foundUsersDialog(args).then(res => {
        if (res) {
          this.task.details.found.push(res.data)
        }
        this.$emit("update:task", res?.data)
      })
    },
    async onClose() {
      await patrolSummaryDialog(this, patrolSummaryCard, TaskService, this.task, this.site).then(res => {
        this.$emit("update:task", res?.data)
      })
    },
    async cancelTask() {
      const result = await this.$dialog.prompt({
        text: "Przyczyna",
        value: "",
        title: "Odwołanie patrolu",
        rules: [],
        actions: {
          false: "Anuluj",
          true: {
            color: "success",
            text: "Odwołaj"
          }
        }
      })
      console.log("result", result)
      if (result !== false && result != undefined) {
        TaskService.cancelTask(this.site.id, this.task.id, result).then(res =>
          this.$emit("update:task", res.data)
        )
      }
    },
    async restartTask() {
      const message = await this.$dialog.warning({
        text: "Czy chcesz zrestartować zadanie?",
        title: "Restart",
        actions: {
          false: "Nie",
          true: {
            color: "success",
            text: "Tak, chcę"
          }
        }
      })
      if (message) {
        console.log("message", message)
        TaskService.restartTask(this.site.id, this.task.id).then(res =>
          this.$emit("update:task", res.data)
        )
      }
    },
    squadInfo() {
      const patrol = firstAssigneeUser(this.task)
      UserService.fetchPatrolSquad(patrol.id, parseJSON(this.task.created_at)).then(res => {
        const squad = res.data.results.map(time => time.user.email).join(", ")
        this.$dialog.show(patrolWorkingTimeCard, {
          readOnly: true,
          patrol: patrol,
          workingTimes: res.data.results,
          title: "Skład",
          text: squad
        })
      })
    },
    async updateDescription() {
      const result = await this.$dialog.prompt({
        text: "Przyczyna",
        value: this.task.description,
        title: "Edycja opisu",
        rules: [],
        actions: {
          false: "Anuluj",
          true: {
            color: "success",
            text: "Zapisz"
          }
        }
      })
      if (result !== false) {
        this.task.description = result
        TaskService.updateTask(this.site.id, this.task.id, this.task).then(res =>
          this.$emit("update:task", res.data)
        )
      }
    },
    async setPayment() {
      setPaymentDialog(this, paidCard, TaskService, this.task, this.site).then(res => {
        this.$emit("update:task", res)
      })
    }
  },
}
</script>
