<template>
  <v-container>
    <v-row v-bind="$attrs" v-on="$listeners">
      <slot />
    </v-row>
  </v-container>
</template>

<script>
export default { name: "AppToolbar" }
</script>
