<template>
  <v-btn color="black" text v-bind="$attrs" v-on="$listeners">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: "DialogBtn",
}
</script>
