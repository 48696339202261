<template>
  <v-col class="ma-0 pa-0">
    <div class="text-body-2"> {{site.city}} {{site.district}}</div>
    <div class="text-body-2">{{site.address}}</div>
  </v-col>
</template>

<script>

export default {
  name: 'SiteAddress',

  computed: {
  },
  props: {
    site: null
  },
}
</script>