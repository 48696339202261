<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :label=label
    outlined
    dense
    clearable
    single-line
    hide-details
    append-icon="mdi-magnify"
  >
    <slot />
  </v-text-field>
</template>
<script>
export default {
  name: "AppFilterField",
  props: {
    label: {
      type: String,
      default: "Filtr"
    },
  }
}
</script>
