import httpClient from './http.client';
import * as Axios from 'axios';
import { CategoryId } from '../store/modules/events';

class EventService {
  getMainConditions() {
    return [
      { id: 'open', name: 'Aktualne', params: { state: 'open' } },
      { id: 'closed', name: 'Zamknięte', params: { state: 'closed' } },
      { id: 'all', name: 'Wszystkie', params: {} },
    ];
  }
  getCategoryConditions() {
    return [
      { id: 'all', code: CategoryId.ALL, name: 'Wszystkie', params: {} },
      {
        id: 'alarm',
        code: CategoryId.ALARM,
        name: 'Alarm',
        params: { category: 'Alarm' },
      },
      {
        id: 'warning',
        code: CategoryId.WARNING,
        name: 'Awaria',
        params: { category: 'Awaria' },
      },
      {
        id: 'status',
        code: CategoryId.STATUS,
        name: 'Status',
        params: { category: 'Status' },
      },
      {
        id: 'service',
        code: CategoryId.SERVICE,
        name: 'Serwis',
        params: { category: 'Serwis' },
      },
      {
        id: 'edit',
        code: CategoryId.EDIT,
        name: 'Edycja bazy',
        params: { category: 'Edycja bazy' },
      },
      {
        id: 'other',
        code: CategoryId.OTHER,
        name: 'Inne',
        params: { category: 'Inne' },
      },
    ];
  }
  getEventOrdering() {
    return [{ name: 'Data utworzenia', ordering: '-created_at' }];
  }
  getEventRanges() {
    return [
      { id: 'last_30_days', name: 'Ostatnie 30 dni' },
      { id: 'today', name: 'Dziś' },
      { id: 'yesterday', name: 'Wczoraj' },
      { id: 'this_month', name: 'Ten miesiac' },
      { id: 'prev_month', name: 'Poprzedni miesiac' },
      { id: 'last_year', name: 'Ostatni rok' },
      { id: 'custom', name: 'Inny' },
    ];
  }
  fetchCodes(query = null) {
    return httpClient
      .get(`v1/events/codes?${query}`)
      .then((response) => response.data);
  }

  createX(siteId, event) {
    return httpClient.post('v1/sites/' + siteId + '/events', event);
  }
  create({ siteId, code, info, userReported, created_at }) {
    let details = {
      info: info,
    };
    if (userReported) {
      details.reported_by = {
        id: userReported.id,
        full_name: userReported.full_name,
      };
    }
    const event = {
      code: code,
      created_at: created_at,
      details: details,
      source: {
        collector_id: 1,
        parsed_data: {},
      },
    };
    return httpClient.post(`v1/sites/${siteId}/events`, event);
  }
  update(event) {
    return httpClient.put(`v1/events/${event.id}`, event);
  }
  delete(event) {
    return httpClient.delete(`v1/events/${event.id}`);
  }
  fetchLasts(limit = 100) {
    return httpClient.get('v1/events' + '?limit=' + limit);
  }
  fetchNew(lastId) {
    return httpClient.get('v1/events' + '?fromId=' + lastId);
  }
  fetchSiteEvents(siteId, params) {
    const query = new URLSearchParams(params).toString();
    return httpClient.get(`v1/sites/${siteId}/events?${query}`);
  }
  fetchEvents(params = null) {
    const query = new URLSearchParams(params).toString();
    return httpClient.get(`v1/events?${query}`);
  }
  fetch(eventId) {
    return httpClient.get('v1/events/' + eventId);
  }
  randomNew(codes) {
    var code = codes[Math.floor(Math.random() * codes.length)];
    var site = Math.floor(Math.random() * 12500);
    console.log('Event generator', code.code, 'siteid', site);
    this.create(site, {
      code: code.code,
      details: {},
      source: {
        collector_id: 1,
        raw_data: '',
        parsed_data: { createdBy: 'automat' },
      },
    });
  }
  download(siteId, params = null) {
    var searchParams = new URLSearchParams(params);
    searchParams.delete('page');
    let source = Axios.CancelToken.source();
    setTimeout(() => {
      source.cancel();
    }, 30000);

    const query = searchParams.toString();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const headers = {"X-Content-Timezone":tz}
    if (siteId) {
      return httpClient.get(`v1/sites/${siteId}/events/download?${query}`, {
        responseType: 'blob',
        headers: headers,
        cancelToken: source.token,
      });
    } else {
      return httpClient.get(`v1/events/download?${query}`, {
        responseType: 'blob',
        headers: headers,
        cancelToken: source.token,
      });
    }
  }
}

export default new EventService();
