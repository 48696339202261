<template>
  <span class="d-flex flex-row align-center v-alert--prominent">
    <v-icon small class="mr-3" :color="model.color"> <!--  class v-alert__icon -->
      {{ model.image }}
    </v-icon>
    <!-- </v-alert> -->
    <span class="text-subtitle-2 ml-2">
      <div>{{ model | title }}
        <tooltip-chip tooltip="Zgłosił" small outlined v-if="note.content.reported_by">
          <v-icon small left> mdi-account-alert-outline </v-icon>
          {{ note.content.reported_by.full_name }}
        </tooltip-chip>
      </div>
      <div>{{ model.content.message }}</div>
    </span>
    <v-spacer />
    <span class="mr-4">
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs1 }">
          <span class="ma-0 pa-0" v-bind="attrs1" v-on="on" align="center">
            <div>
              <v-chip small outlined v-if="isFuture()">
                Start {{ note.beginning_at | formatDistanceWithPrefix }}
              </v-chip>
            </div>
            <div>
              <v-chip small class="green lighten-2">
                Aktywna {{ note.beginning_at | formatDistance }}
              </v-chip>
            </div>
            <div>
              <v-chip small class="red lighten-2 white--text" v-if="!note.expires_at && !isFuture()">
                Do odwołania
              </v-chip>
            </div>
            <div>
              <v-chip small class="red lighten-2 white--text" v-if="note.expires_at && !isFuture()">
                Koniec {{ note.expires_at | formatDistanceWithPrefix }}
              </v-chip>
            </div>
          </span>
        </template>
        <div>Start: {{ note.beginning_at | dateMediumTimeShort }}</div>
        <div v-if="note.expires_at">
          Koniec: {{ note.expires_at | dateMediumTimeShort }}
        </div>
      </v-tooltip>
      <!-- <div>{{ note.beginning_at | dateMediumTimeShort }}</div> -->
    </span>
    <v-btn v-if="editable && !isPast()" icon>
      <v-icon @click="editNote(note)">mdi-pencil</v-icon>
    </v-btn>
    <v-btn v-if="deletable && !isPast()" icon>
      <v-icon @click="deleteNote(note)">mdi-delete</v-icon>
    </v-btn>
  </span>
</template>

<script>
import { Note, NoteType } from "@/store/modules/note"
import SiteService from "@/services/site.service"

export default {
  name: "Note",
  data: () => ({
    model: null
  }),
  beforeMount() {
    this.model = {
      ...this.note,
      ...Note.get(this.note.category)
    }
  },
  filters: {
    title(note) {
      if (note.category == NoteType.GENERAL) {
        return null
      }
      return Note.get(note.category).name
    }
  },
  watch: {
    note() {
      this.template
    }
  },
  computed: {},
  props: {
    note: null,
    editable: Boolean,
    deletable: Boolean
  },
  methods: {
    isFuture() {
      return Note.isFuture(this.note)
    },
    isPast() {
      return Note.isPast(this.note)
    },
    async deleteNote(note) {
      const res = await this.$dialog.confirm({
        text: "Czy na pewno skasować notatkę ?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Skasuj"
          }
        }
      })
      if (res === true) {
        const copyNote = { ...note }
        copyNote.site_id = note.site.id
        SiteService.finishNote(copyNote).then(() => this.$emit('deleted', note))
      }
    },
    editNote(note) {
      this.$router.push({
        name: "Edit Note",
        params: {
          noteId: note.id
        }
      })
    }
  }
}
</script>