import Vue from 'vue'
import _ from 'lodash'

Vue.filter('userName', function(user) {
  // console.log('filter: user :>> ', user);
  if (!user) { return '' }
  if (_.isString(user)) {
    return user
  }
  if (user.name) {
    return user.name
  }
  var name = ''
  if (user.first_name) {
    name = user.first_name
  }
  if (user.last_name) {
    if (name) {
      name += ' '
    }
    name += user.last_name
  }
  if (!name) {
    name = user.name
  }
  if (!name) {
    name = user.username
  }
  return name
})
