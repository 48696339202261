import jwtDecode from 'jwt-decode'
import {
  isValid,
  toDate,
  isBefore,
  differenceInMilliseconds,
  fromUnixTime
} from 'date-fns'

export function decodeJWT (token) {
  return token && jwtDecode(token)
}

export function getTimeDiff (exp) {
  if (!exp) {
    return false
  }
  return differenceInMilliseconds(fromUnixTime(Number(exp)), new Date())
}

export function isValidToken (token) {
  try {
    if (!token) {
      return false
    }
    const expToken = decodeJWT(token).exp
    const expMoment = toDate(expToken * 1000)
    if (isValid(expMoment)) {
      const res = isBefore(new Date(), expMoment)
      return res
    }
    return true
  } catch (e) {
    return false
  }
}
