<template>
  <v-card-subtitle class="my-0 py-1">
    <v-row dense align="center" :class="background" :style="style">
      <v-col cols="3" md="2" align="right">
        <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: event.id } }">
          <event-time :event="event"></event-time>
        </router-link>
      </v-col>
      <v-col cols="5" md="6">
        <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: event.id } }">
          <span class="text-subtitle"> {{ event.code | codeAsString }}</span>
          <span class="text-body-2 ml-2">{{ event.details.info }}</span>

          <div><site-link dense inactive address align="left" :site="event.site"></site-link>
            <v-chip class="mx-1" v-for="cost, index in costs" :key="index" :text-color="cost.textColor" :color="cost.color" x-small>
            <v-icon class="mr-1" :color="cost.textColor" small> mdi-currency-usd </v-icon>
            {{cost.name}}
          </v-chip>
          </div>
        </router-link>
      </v-col>
      <v-col cols="2" md="2">
        <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: event.id } }">
          <task-status-all v-if="taskTree(event.tasks) != null" :task="taskTree(event.tasks)"></task-status-all>
        </router-link>
      </v-col>
      <v-col cols="2" md="2" align="right">
        <toolbar-btn :visible="isOpen" tooltip="Powiadom" icon="mdi-account-alert-outline" @click="notifyUsers" />
        <toolbar-btn :visible="isOpen" tooltip="Anulowane przez klienta" icon="mdi-account-cancel-outline"
          @click="cancelTask" />
        <toolbar-btn :visible="isOpen" tooltip="Zakończ obsługę" icon="mdi-progress-check" @click="closeAllTasks" />
        <!-- <toolbar-btn :visible="!isOpen" tooltip="Rozpocznij obsługę" icon="mdi-progress-star" @click="startDispatcherTask" /> -->
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="py-0 px-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-card-subtitle>
</template>

<script>

import payments from "@/store/modules/payments"
import { taskTree, isOpenTask, updateTask, notifyUsersDialog } from "@/store/modules/task"
import { Categories } from '../store/modules/events';
import TaskService from '../services/task.service';
import SiteService from "@/services/site.service"
import notifyDialog from "@/components/NotifyDialog.vue"
import cancelTaskDialog from "@/components/CancelTaskDialog.vue"
import closeTaskDialog from '@/components/CloseTaskDialog.vue'

export default {
  name: "EventRow",

  beforeMount() {
    this.updateOpen()
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    eventColor() {
      return Categories.get(this.event.code.category)?.color ?? ""
    },
    style() {
      if (this.isOpen) {
        return { 'box-shadow': `-5px 0px 0px 0px ${this.eventColor}, 0px 0px 0px 0px black` }
      } else {
        return ""
      }
    },
    background() {
      if (this.isOpen) {
        return `${this.eventColor} lighten-5 font-weight-black`
      } else {
        return ""
      }
    },
    rootTask() {
      return this.event.tasks.find((task) => {
        return task.parent == null;
      })
    },
    costs() {
      return this.event.tasks
      .filter(task => task.details.cost != null)
      .map(task => task.details.cost)
      .map(cost => {const found = payments.findWithValue(cost.token)
          if (found) {
            return { name: found.short, icon: "mdi-currency-usd", color: found.color, textColor: found.textColor }
          } else {
            return { name: cost.name, icon: "mdi-currency-usd", color: "blue", textColor: "white" }
          }
        })
      },
  },
  watch: {
    event: {
      handler: function () {
        this.updateOpen()
      },
      deep: true,
    },
  },
  methods: {
    taskTree,
    updateOpen() {
      this.isOpen = isOpenTask(this.event?.tasks)
    },
    async closeAllTasks() {
      // const args = { context: this, event: this.event, task: this.rootTask, taskService: TaskService }
      // console.log('args :>> ', args);
      // closeTask(args).then(res => {
      //   if (res == null) { return }
      //   updateTask(this.event.tasks, res.data)
      //   this.updateOpen()
      // })
      const result = await this.$dialog.show(closeTaskDialog, {
        event: this.event,
        waitForResult: true,
        actions: {
          false: 'Anuluj',
          true: {
            color: 'success',
            text: 'OK',
          },
        },
      });
      if (result === false || result === undefined) {
        return;
      }
      const task = this.rootTask;
      console.log('result :>> ', result, task);
      var promises = result.payments
        .filter(
          (payment) => (payment.task?.details?.cost?.token ?? 0) != payment.token
        )
        .map((payment) => {
          console.log(
            'payment promis (set cost):>> ',
            payment.task.details?.cost?.token,
            payment.token
          );
          TaskService.setCost(this.event.site.id, payment.task.id, payment.token);
        });
      Promise.all(promises).then(() => {
        return TaskService.closeTask(this.event.site.id, task.id, result.message, result.comment)
      }).then(res => {
        if (res == null) { return }
        updateTask(this.event.tasks, res.data)
        this.updateOpen()
      });
    },

    notifyUsers() {
      const args = {
        context: this,
        notifyDialog: notifyDialog,
        taskService: TaskService,
        siteService: SiteService,
        event: this.event,
        task: this.rootTask,
      }
      notifyUsersDialog(args).then((response) => console.log('object :>> ', response))
    },
    async cancelTask() {
      const dialogInstance = await this.$dialog.show(cancelTaskDialog, {
        users: new Promise(resolve => {
          SiteService.fetchMembers(this.event.site.id).then(result =>
            resolve(result.map(member => member.user))
          )
        })
      })
      dialogInstance.waitForResult = true
      dialogInstance.wait().then(result => {
        if (!result) {
          return
        }
        TaskService.cancelTask(
          this.event.site.id,
          this.rootTask.id,
          result.message,
          result.comment,
          result.user
        ).then(res => {
          if (res == null) { return }
          updateTask(this.event.tasks, res.data)
          this.updateOpen()
        })
      })
    },
  },
  props: {
    event: {
      type: Object,
      default: null
    },
    siteHidden: {
      type: Boolean,
      default: null
    }
  }
}
</script>

<style >
.indicator {
  /* outline: 5px solid black; */
  /* border-left: 15px solid red; */
  /* border-top-left-radius: 5px; */
  /* border-top-right-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  /* border-bottom-right-radius: 5px; */
  box-shadow: -5px 0px 0px 0px red, 0px 0px 0px 0px black;
}
</style>