import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import i18n from './i18n'
import VueLogger from 'vuejs-logger'
import './plugins'
import { sync } from 'vuex-router-sync'

import x5GMaps from 'x5-gmaps'
Vue.use(x5GMaps, { key: 'AIzaSyDkcPKGZDWKyPMHRFjzE_MEqDyJ1GFik70', libraries: ['places'] })

Vue.config.productionTip = false

sync(store, router)

const isProduction = process.env.NODE_ENV === 'production'


const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, options)

const ver = process.env.VUE_APP_VERSION.split('-')
console.log("Version:", ver[0])
console.log("Build:", ver[1])
console.log("Date:", new Date(ver[2] * 1000))
// const initAuth = async () => {
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
// }
// initAuth()