<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    dense
    return-object
    hide-details
    label="Sortowanie"
  >
  </v-select>
</template>

<script>
export default { name: "AppSortSelect" }
</script>
