<template>
  <v-container>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="6">
        </v-col>
        <v-col cols="2" align="center" >
          Uprawnienia
        </v-col>
        <v-col cols="3" align="center">
          Dodany
        </v-col>
        <v-col cols="1" align="center">
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="pb-0" v-for="member of members" :key="member.id">
      <v-row align="center">
        <v-col cols="6">
         <site-link v-if="member.site" :site="member.site"></site-link>
         <h4 v-if="!member.site">Dostęp globalny</h4>
       </v-col>
       <v-col cols="2" align="center">
         <v-select
         :disabled="true"
         hide-details
         @change="update(member)"
         dense outlined
         v-model="member.access_level"
         :items="accessLevels">
       </v-select>
     </v-col>
     <v-col cols="3" align="center">
      {{ member.created_at | dateMedium }}
    </v-col>
    <v-col cols="1" align="center">
      <icon-btn
      tooltip="Zablokuj dostęp"
      icon="mdi-delete"
      @click="remove(member)"></icon-btn>
    </v-col>
  </v-row>
  <v-col cols="12" class="pb-0">
    <v-divider></v-divider>
  </v-col>
</v-container>
</v-container>
</template>

<script>
import _ from 'lodash'
import SiteService from "@/services/site.service"
import UserService from "@/services/user.service"
import SiteMember from "@/store/modules/siteMember"

export default {
  name: "UserAccessCard",
  mounted() {
    this.refresh()
  },
  data: () => ({
    accessLevels: SiteMember.accessLevels(),
    members: []
  }),
  props: {
    user: null,
  },
  watch: {
    user() {
      this.refresh()
    }
  },
  methods: {
    refresh() {
      if (!this.user?.id) {
        return
      }
      UserService.userMemberships(this.user?.id)
      .then(members => {
        this.members = members
      })
    },
    add(member) {
      console.log("add member", member)
      this.members.push(member)
    },
    remove(member) {
      console.log("remove", member)
      SiteService.deleteMember(member.site?.id ? member.site.id : "-", member.id).then(() => this.members = _.remove(this.members, (n) => n !== member ))
    },
    update(member) {
      console.log(member.access_level)
      SiteService.updateMember("0", member.id, {
        access_level: member.access_level
      })
    },
  }
}
</script>