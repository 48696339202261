export class Payments {
  payments = [
    {
      value: 1,
      name: 'System Sygnalizacji Włamania i Napadu',
      short: 'SSWIN',
      color: 'red darken-1',
      textColor: 'white',
    },
    {
      value: 2,
      name: 'System przeciwpożarowy',
      short: 'P-POŻ',
      color: 'blue darken-1',
      textColor: 'white',
    },
    {
      value: 3,
      name: 'System wizyjny',
      short: 'CCTV',
      color: 'green darken-1',
      textColor: 'white',
    },
    {
      value: 4,
      name: 'Pomoc techniczna',
      short: 'PT',
      color: 'grey darken-1',
      textColor: 'white',
    },
  ];

  // iconName(taskType) {
  //     switch (taskType) {
  //         case 'dispatcher':
  //         case UserTaskType.DISPATCHER:
  //             return "mdi-account-tie"
  //         case 'patrol':
  //         case UserTaskType.PATROL:
  //             return "mdi-car-emergency"
  //         case 'service':
  //         case UserTaskType.SERVICE:
  //             return "mdi-cog-outline"
  //     }
  // }

  empty() {
    return {
      value: 0,
      name: '-',
      short: '-',
      color: 'grey darken-1',
      textColor: 'white',
    };
  }
  all() {
    return this.payments;
  }

  findWithValue(value) {
    return this.payments.find((v) => v.value == value);
  }
}

export default new Payments();
