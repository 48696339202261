var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.events,"items-per-page":15,"item-class":_vm.rowClass,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.costs",fn:function(ref){
var item = ref.item;
return [(_vm.isPaid(item))?_c('v-icon',{attrs:{"dense":"","small":""}},[_vm._v(" mdi-currency-usd ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.closed)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.closeItem(item)}}},[_vm._v(" mdi-close ")]):_vm._e()]}},{key:"item.code.category",fn:function(ref){return [_c('v-icon',[_vm._v(" mdi-bell ")])]}},{key:"item.code.text",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Event', params: { id: item.id } }}},[_vm._v(_vm._s(item.code.text))])]}},{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.mediumDataTime(value))+" ")]}},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return _vm._l((item.tasks),function(task,i){return _c('v-avatar',{key:i,staticClass:"ma-2",class:_vm.avatarClass(task)},[_c('task-icon',{attrs:{"normal":"","task":task.task_type}})],1)})}}])},'v-data-table',_vm.$attrs,false),[_vm._v(" >")])}
var staticRenderFns = []

export { render, staticRenderFns }