<template>
  <v-card-subtitle class="my-0 py-1">
    <v-row dense align="center" :class="rowClass">
      <v-col cols="4" md="4" class="pa-0">
        <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: task.event.id } }">
          <v-container flat class="pa-0 d-flex flex-row align-center">
            <user-avatar class="flex-grow-0" v-if="task.assignees && task.assignees.length > 0"
              :user="task.assignees[0].user"></user-avatar>
            <task-status-all class="flex-grow-1 ml-2" visibleLabel :task="task"></task-status-all>
          </v-container>
        </router-link>
      </v-col>
      <v-col cols="7" md="7">
        <router-link class="text-decoration-none" :to="{ name: 'Event', params: { id: task.event.id } }">
          <span class="v-card-subtitle"> {{ task.event.code | codeAsString }}</span>
          <span class="text-body-2 ml-2">{{ task.event.details.info }}</span>
        </router-link>
        <div><site-link dense align="left" :site="task.event.site"></site-link></div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="py-0 px-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </v-card-subtitle>
</template>

<script>

export default {
  name: "TaskRow",

  computed: {
    rowClass() {
      if (this.isOpen) {
        return "red lighten-5 font-weight-black indicator"
      } else {
        return ""
      }
    },
    isOpen() {
      return this.task.closed_at == null
    },
  },
  props: {
    task: {
      type: Object,
      default: null
    }
  }
}
</script>
