import httpClient from './http.client'
import SiteMember from "@/store/modules/siteMember"

class SiteService {
  getMainConditions() {
    return [
      { id: "active", name: "Aktywni", conditions: ["is_active=true"] },
      { id: "blocked", name: "Zablokowani", conditions: ["is_active=false"] },
      { id: "all", name: "Wszyscy", conditions: [] }
    ]
  }
  getMainOrdering() {
    return [
      { name: "Data modyfikacji", ordering: null },
      { name: "Nazwa", ordering: "name" },
      { name: "Nazwa, malejąco", ordering: "-name" },
      { name: "Kryptonim", ordering: "code_name" },
      { name: "Kryptonim, malejąco", ordering: "-code_name" }
    ]
  }
  getType() {
    return [
      { id: "active", name: "Aktywni", conditions: ["is_active=true"] },
      { id: "blocked", name: "Zablokowani", conditions: ["is_active=false"] },
      { id: "all", name: "Wszyscy", conditions: [] }
    ]
  }
  getNotesRanges() {
    return [
      { id: "current", name: "Aktualne", conditions: [] },
      { id: "future", name: "Przyszłe", conditions: ["date=future"] },
      { id: "last", name: "Ostatnie", conditions: ["date=last_30_days"] }
    ]
  }
  fetch(search = "", conditions = ['is_active=True'], page = 1, ordering = null) {
    let query = httpClient.buildQuery({
      search: search, conditions: conditions, page: page, ordering: ordering
    })
    return httpClient.get(`v1/sites?${query}`).then(res => res.data)
  }
  get(id) {
    return httpClient.get('v1/sites/' + id)
  }
  createOrUpdateSite(site) {
    if (site.id) {
      return httpClient.put(`v1/sites/${site.id}`, site)
    } else {
      return httpClient.post('v1/sites', site)
    }
  }
  fetchWithGuid(guid) {
    return httpClient.get(`v1/sites?guid=${guid}`).then((res) => res.data)
  }
  fetchWithCodeName(codeName, exact = false) {
    if (exact) {
      return httpClient.get('v1/sites?is_active=true&code_name=' + codeName)
    } else {
      return httpClient.get('v1/sites?is_active=true&code_name__startswith=' + codeName)
    }
  }
  fetchMembers(siteId) {
    return httpClient.get(`v1/sites/${siteId}/members`)
      .then((res) => res.data)
      .then(data => {
        for (var record of data) {
          record.user.tags = [
            {
              title: SiteMember.accessLevelName(record.access_level),
              color: null
            }
          ]
          if (!record.site) {
            record.user.tags.push({
              title: "Dostęp globalny",
              color: "success"
            })
          }
        }
        return data
      })
  }
  deleteMember(siteId, memberId) {
    return httpClient.delete(`v1/sites/${siteId}/members/${memberId}`)
  }
  updateMember(siteId, memberId, payload) {
    return httpClient.patch(`v1/sites/${siteId}/members/${memberId}`, payload)
  }
  createMember(siteId, members) {
    return httpClient.post(`v1/sites/${siteId}/members`, members).then(
      (res) => {
        return res.data
      }
    );
  }
  getFreeCodenames(startsWith) {
    return httpClient.get(`v1/sites/codenames?code_name=${startsWith}`,).then((res) => res.data)
  }
  createCodenames(codenames) {
    return httpClient.post(`v1/sites/codenames`, codenames).then((res) => res.data)
  }
  createNote({ siteId, beginningAt, expiresAt, message, reportedBy, category }) {
    let note = {
      site_id: siteId,
      category: category,
      beginning_at: beginningAt.toISOString(),
      expires_at: expiresAt ? expiresAt.toISOString() : null,
      content: { message: message},
    };
    if (reportedBy) {
      note.content.reported_by = {
        id: reportedBy.id,
        full_name: reportedBy.full_name,
      };
    }
    return httpClient.post(`v1/sites/${siteId}/notes`, note)
  }
  getNote(id) {
    return httpClient.get(`v1/notes/${id}`).then((res) => res.data)
  }
  getNotes(siteId, args = { search: "", conditions: [], page: 1, ordering: null }) {
    return httpClient.get(`v1/sites/${siteId}/notes?${httpClient.buildQuery(args)}`)
  }
  fetchNotes(args = { search: "", conditions: [], page: 1, ordering: null }) {
    return httpClient.get(`v1/notes?${httpClient.buildQuery(args)}`).then(res => res.data)
  }
  updateNote({ id, siteId, beginningAt, expiresAt, message, reportedBy }) {
    let note = {
      site_id: siteId,
      beginning_at: beginningAt.toISOString(),
      expires_at: expiresAt ? expiresAt.toISOString() : null,
      content: { message: message},
    };
    if (reportedBy) {
      note.content.reported_by = {
        id: reportedBy.id,
        full_name: reportedBy.full_name,
      };
    }
    return httpClient.put(`v1/notes/${id}`, note).then((res) => res.data)
  }
  deleteNote(id) {
    return httpClient.delete('v1/notes/' + id)
  }
  finishNote(note) {
    note.expires_at = new Date().toISOString()
    return httpClient.delete('v1/notes/' + note.id, note)
  }
}

export default new SiteService()
