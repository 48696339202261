<template>
  <v-container>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="6">
        </v-col>
        <v-col cols="2" align="center" >
          Uprawnienia
        </v-col>
        <v-col cols="3" align="center">
          Dodany
        </v-col>
        <v-col cols="1" align="center">
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container class="pb-0" v-for="member of members" :key="member.id">
      <v-row align="center" v-if="member.site">
        <v-col cols="6">
         <user-info :user="member.user"></user-info>
       </v-col>
       <v-col cols="2" align="center">
         <v-select
         hide-details
         disabled
         @change="update(member)"
         dense outlined
         v-model="member.access_level"
         :items="accessLevels">
       </v-select>
       <!-- {{ accessLevel(user.member.access_level) }} -->
     </v-col>
     <v-col cols="3" align="center">
      {{ member.created_at | dateMedium }}
    </v-col>
    <v-col cols="1" align="center">
      <icon-btn
      v-if="member.site"
      tooltip="Zablokuj dostęp"
      icon="mdi-delete"
      @click="remove(member)"></icon-btn>
    </v-col>
  </v-row>
  <!-- <v-col cols="12">{{user}}</v-col> -->
  <v-col cols="12" class="pb-0">
    <v-divider></v-divider>
  </v-col>
</v-container>
</v-container>
</template>

<script>
import _ from 'lodash'
import SiteService from "@/services/site.service"
import SiteMember from "@/store/modules/siteMember"

export default {
  name: "SiteMemberCard",
  mounted() {
    this.refresh()
  },
  data: () => ({
    accessLevels: SiteMember.accessLevels(),
    members: []
  }),
  props: {
    site: null,
  },
  watch: {
    site() {
      this.refresh()
    }
  },
  methods: {
    refresh() {
      if (!this.site?.id) {
        return
      }
      SiteService.fetchMembers(this.site?.id)
      .then(members => {
        this.members = members
      })
    },
    add(member) {
      console.log("add member", member)
      this.members.push(member)
    },
    async remove(member) {
        const res = await this.$dialog.confirm({
        text: "Czy na pewno skasować dostęp?",
        title: "",
        actions: {
          false: "Nie",
          true: {
            color: "error",
            text: "Skasuj"
          }
        }
      })
      if (res === true) {
        SiteService.deleteMember(this.site.id, member.id).then(() => this.members = _.remove(this.members, (n) => n !== member))
      }
    },
    update(member) {
      console.log(member.access_level)
      SiteService.updateMember(this.site.id, member.id, {
        access_level: member.access_level
      })
    },
  }
}
</script>