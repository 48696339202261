<template>
  <v-tooltip bottom v-if="visible">
    <template v-slot:activator="{ on }">
      <v-btn
      icon
      v-bind="$attrs"
      v-on="on"
      class="mx-1"
      :color="color"
      @click="onClick">
      <v-icon :small="small">{{icon}}</v-icon>
    </v-btn>
    <slot/>
  </template>
  <span>{{tooltip}}</span>
</v-tooltip>
</template>

<script>
export default {
  name: 'IconBtn',

  props: {
    visible: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: "mdi-car"
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
