<template>
  <v-row dense align="center">
    <v-col cols="12">
      <v-menu ref="menuDate" v-model="menuDate" :close-on-content-click="false" :return-value.sync="dates"
        transition="scale-transition" left min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field dense readonly v-model="dateRangeText" :label="label" :disabled="disabled" v-bind="attrs" outlined
            hide-details="true" v-on="on"></v-text-field>
        </template>
        <v-card flat>
          <v-row dense no-gutters>
            <v-col>
              <v-list flat>
                <v-subheader>Wybierz zakres</v-subheader>
                <v-list-item-group v-model="selectedItem" color="primary" mandatory>
                  <v-list-item v-for="(item, i) in items" :key="i">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <v-date-picker class="mb-n7" :disabled="!custom" v-model="datesInternal" no-title range scrollable>
              </v-date-picker>
              <v-row class="mx-5">
                <v-col cols="6">
                  <v-text-field :disabled="!custom" v-model="fromHour" label="Od godziny" type="time"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field :disabled="!custom" v-model="toHour" label="Od godziny" type="time"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mx-5">
                <v-btn :disabled="!custom" text color="primary" @click="menuDate = false">
                  {{ $t("event.picker.cancel") }}
                </v-btn>
                <v-btn :disabled="!custom" text color="primary" @click="$refs.menuDate.save(datesInternal)">
                  {{ $t("event.picker.ok") }}
                </v-btn>
              </v-row>
            </v-col>

          </v-row>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { isValid, add, formatISO, parseISO, isEqual, format } from "date-fns"

export default {
  name: "DateRangeEditor",
  props: {
    value: {
      type: [Object, Number],
      default: null
    },
    label: {
      type: String,
      default: () => "Zakres"
    },
    disabled: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => [
        { id: "last_30_days", name: "Ostatnie 30 dni" },
        { id: "today", name: "Dziś" },
        { id: "yesterday", name: "Wczoraj" },
        { id: "this_month", name: "Ten miesiac" },
        { id: "prev_month", name: "Poprzedni miesiac" },
        { id: "last_year", name: "Ostatni rok" },
        { id: "custom", name: "Inny" }
      ]
    }
  },
  beforeMount() {
    this.updateForm()
  },
  data: () => ({
    datesInternal: [],
    dates: [],
    fromHour: "00:00",
    toHour: "00:00",
    menuDate: null,
    custom: true,
    selectedItem: 1
  }),
  watch: {
    value() {
      this.updateForm()
    },
    selectedItem(value) {
      const model = this.items[value]
      if (!model) {
        return
      }
      this.custom = model.id == "custom"
      if (!this.custom) {
        this.menuDate = false
        this.$emit("input", model)
      }
    },
    dates: function (value) {
      this.$emit("input", this.payload(value))
    }
  },
  computed: {
    dateRangeText() {
      const model = this.items[this.selectedItem]
      if (this.custom) {
        var sort = this.datesInternal
        return sort.sort().join(" ~ ")
      } else {
        return model.name
      }
    }
  },
  methods: {
    updateForm() {
      if (this.value) {
        this.selectedItem = this.items.findIndex(c => c.id == this.value.id)
        if (this.value.from && this.value.to) {
          const from = formatISO(parseISO(this.value.from), {
            representation: "date"
          })
          const to = formatISO(parseISO(this.value.to), {
            representation: "date"
          })
          this.datesInternal = [from, to]
          this.fromHour = format(parseISO(this.value.from), "HH:mm")
          this.toHour = format(parseISO(this.value.to), "HH:mm")
        }
      }
    },
    payload(dates) {
      var from, to

      const model = this.items[this.selectedItem]


      var representationFrom = "date"
      var representationTo = "date"

      if (dates[1] < dates[0]) {
        from = parseISO(dates[1] + " " + this.fromHour)
        to = parseISO(dates[0] + " " + this.toHour)
      } else {
        from = parseISO(dates[0] + " " + this.fromHour)
        to = parseISO(dates[1] + " " + this.toHour)
      }

      if (!isValid(from)) {
        console.log("From is invalid", from)
        return {}
      }
      if (!isValid(to) || isEqual(from, to)) {
        to = add(from, { days: 1 })
      }

      if (this.fromHour != "00:00") {
        representationFrom = "complete"
      } else {
        from.setHours(0, 0, 0, 0)
      }
      if (this.toHour != "00:00") {
        representationTo = "complete"
      } else {
        to.setHours(0, 0, 0, 0)
      }

      return {
        ...model,
        from: formatISO(from, { representation: representationFrom }),
        to: formatISO(to, { representation: representationTo })
      }
    }
  }
}
</script>