<template #map>
  <gmaps-map/>
</template>

<script>
import { gmapsMap } from 'x5-gmaps';

export default {
  name: 'SimpleMap',
  components: { gmapsMap }
};
</script>
