<template>
  <app-tasks
    :tabs="conditions"
    @refresh-request="refresh"
    :tasks="tasks"
    :count="count"
    :pages="pages"
  ></app-tasks>
</template>

<script>
import TaskService from "@/services/task.service"

export default {
  name: "AppTasksSite",
  data() {
    return {
      tasks: [],
      pages: 0,
      count: 0
    }
  },
  props: {
    site: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    site() {
      this.refresh()
    }
  },
  computed: {
    conditions() {
      return TaskService.getSiteConditions()
    }
  },
  methods: {
    refresh(context) {
      if (!this.site?.id) {
        return
      }
      TaskService.fetchSiteTasks(
        this.site.id,
        context?.query
      ).then(data => {
        this.tasks = []
        for (const task of data.results) {
          this.updateLastUpdatedTask(task)
        }
        this.pages = data.pages
        this.count = data.count
        this.tasks = data.results
      })
    },
    updateLastUpdatedTask(task) {
      if (task.modified_at > this.lastUpdatedTask) {
        this.lastUpdatedTask = task.modified_at
      }
    }
  },
  beforeMount() {
    // this.refresh()
  }
}
</script>
