<template>

      <v-btn  v-if="params.badge > 0" icon @click="params.click(params.data)">
          <v-badge
        bordered
        color="error"
        :content="params.badge"
        overlap
        :value="params.badge > 0"
      >
      <v-icon>mdi-bell</v-icon>
      </v-badge>
      </v-btn>
</template>

<script>

export default {
  name: 'TasksIconBadge',

  computed: {
  },
  props: {
    // badge: {type: Number, default: 10},
  },
}
</script>